<template>
    <section>
        <div class="doctor">
            <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
            <div class="doctordiv">
                <div>
                    <img src="../assets/Icons/DrDikshaSareen.png">
                </div>
                <div class="doctordetail">
                    <h1>Dr. Diksha Sareen</h1>
                    <!-- <h2>Founder</h2> -->
                    <hr>
                    <p class="degree">M.B.B.S, M.S, D.N.B (Ophth) FLVPEI</p>
                    <p>Cornea Specialist</p>
                </div>
            </div>
            <div class="biography">
                <p>Dr. Diksha Sareen, a distinguished medical professional with an impressive educational background, is a Cornea and Phaco surgeon with a wealth of expertise. She completed her MBBS and MS at the prestigious IMS, BHU, showcasing her commitment to excellence in medical education. Further enriching her knowledge and skills, she pursued a DNB, demonstrating her dedication to advancing her medical career.
                    <br><br>
                Dr. Diksha Sareen honed her specialization by undertaking a Cornea and Anterior Segment fellowship at the renowned L V Prasad Eye Institute in Bhubaneswar. This invaluable experience deepened her understanding of the intricate aspects of corneal and anterior segment surgeries.
                <br><br>
                Throughout her career, Dr. Diksha Sareen has exhibited unwavering dedication to her patients, providing compassionate care and surgical precision. Her commitment to advancing the field of corneal surgery and phacoemulsification has earned her a reputation as a leading expert in her field.
                <br><br>
                With an impressive academic foundation and extensive experience, Dr. Diksha Sareen continues to make a significant impact in the medical community. Her dedication to excellence and her patients make her an invaluable asset to the field of Cornea and Phaco surgery.</p>
            </div>
            <div class="minidoctordetail">
                <RouterLink to="/DrShantanuGupta">
                <div class="minidoctor"> 
                    <img src="../assets/Icons/DrShantanuGupta.png">
                    <div class="minidoctorsec">
                        <h2>Dr. Shantanu Gupta </h2>
                        <p class="degrees">M.B.B.S, M.S(MAMC,Delhi) D.N.B, F.I.C.O(UK)</p>
                        <p>Medical Retina Surgeon</p>
                        <RouterLink to="/DrShantanuGupta" class="router">
                            <button> Know More</button>
                        </RouterLink>
                        </div>
                    </div>
                </RouterLink>
                <RouterLink to="/DrRSingh">
                        <div class="minidoctor"> 
                            <img src="../assets/Icons/DrRSingh.png">
                            <div class="minidoctorsec">
                                <h2>Dr. Ranjit Singh </h2>
                                <p class="degrees">M.B.B.S, M.S</p>
                                <p>Ophthalmologist</p>
                                <RouterLink to="/DrRSingh" class="router">
                                    <button> Know More</button>
                                </RouterLink>
                            </div>
                        </div>
                    </RouterLink>
            </div>
        </div>
    </section>
</template>

<script>
import { RouterLink } from 'vue-router';
    export default{
        name:'DoctorDikshaSection',
        components:{ RouterLink}
    }
</script>



<style>

/* 
    .doctor{
        background-image: url('../assets/Bg Images/About Us BG img.png');
        background-size: cover;
        background-position: center;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-family: sans-serif;
    }

    .doctordiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px;
    }

    .doctordetail{
        text-align: start;
        padding: 20px;
    }

    .doctordetail hr{
        width: 520px;
        padding: 2px;
        border: 0;
        border-radius: 10px;
        background-color: var(--bluecolor);
    }

    .doctordetail h1{
        font-size: 3rem;
        font-family: 'Montserrat';
        color: var(--bluecolor);
        padding: 10px;
    }

    .doctordetail h2{
        font-size: 2rem;
        padding: 10px;
    }

    .doctordetail p{
        padding: 10px;
        color: red;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .doctordetail .router{
        padding: 20px 10px 10px 10px;
    }

    .doctordetail button{
        font-size: 1rem;
        padding: 15px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .biography{
        background-color: var(--bluecolor);
        text-align: start;
        padding: 40px 100px;
        color: var(--whitecolor);
        margin: 0px 150px 0px 150px;
        border-radius: 20px;
    }    


    .biography h2{
         padding: 10px;
        font-size: 2rem;
    }

    .biography p{
        padding: 40px 10px;
        font-family: 'Nunito';
        font-size: 1.5rem;
    }

    .minidoctordetail{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 20px   ;
    }
    
    .minidoctor{
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: var(--whitecolor);
        padding: 10px;
        box-shadow: 20px 20px 30px var(--thirdblack);
    }

    .minidoctorsec{
        display: flex;
        flex-direction: column;
    }

    .minidoctor img{
        width: 30%;
    }

    .minidoctorsec h2{
        color: var(--bluecolor);
        padding: 10px;
    }

    .minidoctorsec p{
        color: var(--blackcolor);
    }

    .minidoctorsec button{
        font-size: 1rem;
        padding: 15px 40px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .minidoctorsec .router{
        padding: 10px ;
    } */

</style>