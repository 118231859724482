export default{
        hlv:'Hospital Location in Varanasi',
        trv:"Top Rated in Varanasi",
        Homemsg:'Home',
                Servicesmsg:'Services',
                Option1:'  Lasik Vision  ',
                Option2:'  Cataract Surgery  ',
                Option3:'  Refractive Solutions  ',
                Option4:'  Retinal Care  ',
                Option5:'  Glaucoma Management  ',
                Option6:'  Cornea Transplants  ',
                OurDoctormsg:'Our Doctors',
                Aboutmsg:'About',
                BookanAppointmentmsg:'Book an Appointment',
                tcoym:'Keep your Eyes Healthy and Happy!',
                landmsg:"See the world clearly with expert eye care at your service. ",
                spc:'Specialist Eye - Care',
                asa:"Ayushmaan scheme available",
                ehcs:'Ex-Servicemen Contributory Health Scheme',
                eycdmsg:'Enter Your contacts below to schedule an appointment or discuss it with us in details',
                hourservice:'24 Hours Service',
                cataract:'CATARACT',
                surgery:'Surgery',
                nbns:'NO BLADE, NO STITCH',
                npni:'NO PAIN, NO INJECTION',
                suedo:'SURGERY UNDER EYE DROPS ONLY',
                abtmsg1:'Welcome to Kashi Netralaya, a renowned eye hospital founded by Dr. R. Singh. We are located in Varanasi, India, and are well-known for our expert eye care services and commitment to providing top-quality treatment.',
                abtmsg2:"At Kashi Netralaya, our main goal is to help people with their eye problems and keep their eyes healthy. We have a team of skilled doctors and advanced technology to provide a wide range of specialised services. This includes surgeries for issues like cataracts, refractive problems, retinal concerns, glaucoma, and Cornea transplants. We also offer services specifically for children's eye health.",
                abtmsg3:"We want to make sure that you get the best care for your eyes, and our team is here to help you. So, if you have any eye-related concerns, feel free to reach out to us. Your vision is important to us, and we're honoured to be your trusted partner in eye care. Come visit Kashi Netralaya and let us help you see a brighter future.",
                Option12:'Specs Removal',
                Option13:'Sharpest Vision',
                Option14:'100% Blade-free Specs Removal',
                Option15:'Enhanced Safety',
                add1:'Kashi netralaya, 27/A, Bhubaneswar Nagar Colony,',
                add2:'Tagore Town, Orderly Bazar, Varanasi,',
                add3:'Uttar Pradesh 221002',
                Option21:'Crystal Clear Vision',
                Option22:'Swift and effective cataract removal',
                Option23:'Restoration of vibrant, clear vision',
                Option24:'Advanced, blade-free cataract surgery',
                Option31:'Visual Freedom',
                Option32:'Freedom from glasses and contact lenses',
                Option33:'Tailored refractive procedures',
                Option34:'Precision and accuracy in vision correction',
                Option41:'Protecting Your Retina',
                Option42:'Comprehensive retinal diagnostics',
                Option43:'Expert treatment for retinal disorders',
                Option44:'Preserving your sight and eye health',
                Option51:'Managing Intraocular Pressure',
                Option52:' Early detection and diagnosis',
                Option53:' Customized treatment plans',
                Option54:'Preventing vision loss through expert care',
                Option61:'Renewed Vision',
                Option62:'Restoring damaged corneas to improve vision',
                Option63:'Life-changing Cornea transplant procedures',
                Option64:'Skilled surgeons and cutting-edge techniques',
                read:'Read more',
                about_us:'About Us',
                getIn_touch:'Get in touch',
                Submit:'Submit',
                call:'Call Us',
                discount:'40% Discount',
                placeholder:'Your Name*',
                placeholder2:'Phone number*',
                book_now:'Book now',
                connect:'Connect with us',
                brand_name:'KASHI NETRALAYA EYE CARE AND LASIK LASER CENTRE ',
                testi_head:'Testimonials',
                testi_hii:'Discover how our life-changing eye surgeries have transformed the vision and lives of our valued patients.',
                disclaim:'Disclaimer',
                disclaimsg1:'The Information on this site should not be considered as medical advice. Please consult a doctor for determining the best procedure as well as the most appropriate lens for your eyes.',
                disclaimsg2:'The results may vary from person to person and we do not guarantee similar results for everyone.',
                cat1:"We aim to provide our patients with the best treatment for cataracts as we are one of the best eye hospitals in Varanasi for cataract operations. We have the best cataract surgeons in Varanasi who are highly trained in what they do and continuously upgrade their professional competence using various opportunities available.",
                cat2:'Cataract can be caused by old age, smoking, obesity, diabetes, trauma, radiation therapy, or due to inflammation of internal eye structures.',
                cat3:'Cataract symptoms include hazy vision, trouble seeing at night, seeing colors as faded, increased sensitivity to glare, double vision in the affected eye, and halos surrounding lights.',
                cat4:"Surgery is recommended for treating cataracts.",
                cat44:'One surgical method, known as phacoemulsification, involves using ultrasound waves to break the lens apart and remove the pieces. If you are looking for “best doctors for cataract surgery near me” in Varanasi, we at Kashinetralaya have got your back.',
                cat5:'Phacoemulsification with Foldable IOL:',
                cat55:' A small Cornea incision of 2.8 mm is done. Then the cataract is removed by ultrasonic phacoemulsification probe, and the IOL is implanted.',
                cat6:'Multifocal IOL: ',
                cat66:'Multifocal contact lenses are implanted as a solution to correct both far and near vision without spectacles. A small percentage of patients may need small power occasionally.',
                cat7:'Micro-Coaxial Phaco Surgery: ',
                cat77:'This technique reduces the incision to less than 2.2 mm. In this procedure, micro-coaxial phacoemulsification, irrigation, aspiration, and phacoemulsification are performed with the instruments used in standard coaxial small incision cataract surgery.This surgery has nearly all the advantages of the standard coaxial small incision cataract surgery while making smaller incisions. Therefore, patients have quicker woundhealing, smaller SIAs, better postoperative visual sharpness, lower risk of infection, and more stable Cornea biomechanics following their procedures.',
                cat8:'Premium Cataract Surgery Options:',
                cat88:' We also provide premium options for cataract surgery such as Mono-vision, Toric IOLs, Aspheric IOLs, and Multifocal IOLs that enable the vision for distance, intermediate, and near tasks without blurred zones.',
                cat9:'If you’re looking for “cataract surgery doctors near me” or “cataract surgeons near me,”',
                cat99:'KASHI NETRALAYA EYE CARE AND LASIK LASER CENTRE in VARANASI ',
                cat999:'or',
                cat9999:' click here',
                cat99999:' for an appointment.',
                catsurgh1:'Cataract Treatment',
                catsurgh2:'Causes',
                catsurgh3:'Symptoms',
                catsurgh4:'Treatment',
                catsurgh5:'Cataract Services Provided by Us',
                lasikh1:'Lasik Vision',
                lasik1:'At Kashinetralaya, our medical experts provide the highest quality eye care services to our patients. Our eye specialists provide the highest patient care standards, from cataract surgeries to refractive error treatments. Refractive error refers to the shape of the eye that does not bend light correctly. This results in blurred vision. Refraction in the eye can make it difficult to see clearly.',
                lasik11:'The basic refractive errors include myopia (near sightedness), presbyopia (difficulty in viewing nearby objects due to age), hyperopia (far sightedness), and astigmatism.',
                lasikh2:'Symptoms',
                lasikh3:'Causes',
                lasik2:'There are various symptoms of refractive error:',
                lasik21:'Hazy Vision',
                lasik22:'Seeing a glare or a halo around bright lights',
                lasik23:'Squinting',
                lasik24:'Headaches',
                lasik25:'Eye strain (when the eyes feel tired or sore)',
                lasik26:'Trouble focusing when reading or looking at a computer',
                lasik3:'The causes of the refraction disorders of the eye are described below:',
                lasik31:'Myopia:',
                lasik311:'Myopia is a common vision condition where you can view objects near you clearly, but things farther away appear unclear. Also known as nearsightedness, Myopia is inherited and often discovered in childhood and progresses.',
                lasik32:'Hyperopia:',
                lasik322:'Also known as farsightedness, this can also be inherited. In mild hyperopia, the distance vision is clear, while near vision is blurry.',
                lasik33:'Presbyopia:',
                lasik333:'The eye lens becomes more rigid and does not flex as quickly and easily as it should. Therefore the eye cannot focus properly, and it becomes more difficult to read or see nearby objects clearly. It becomes noticeable by the age of 40.',
                lasik34:'Astigmatism:',
                lasik344:'It usually occurs when the cornea, i.e., the front surface of the eye, has an irregular shape or curvature. This anomaly may result in sight being similar to viewing into a distorted mirror.',
                lasikh4:'Our Services',
                lasik41:'LASIK',
                lasik411:'Laser-Assisted in Situ Keratomileusis (LASIK) is a procedure involving using laser technology to correct Cornea errors of refraction. It is completely safe and painless in treating such errors.',
                lasik9:'If you’re looking for “Lasik surgery doctors near me” or “Lasik surgeons near me,”',
                lasik99:'KASHI NETRALAYA EYE CARE AND LASIK LASER CENTRE in VARANASI ',
                lasik999:'or',               
                lasik9999:' click here',
                lasik99999:' for an appointment.',

                //glyconma
                gly1:'Glaucoma Treatment',
                glypara1:'We aim to provide our patients with the best treatment for glaucoma as we are one of the best eye hospitals in Varanasi for glaucoma operations. We have the best cataract surgeons in Varanasi who are highly trained in what they do and continuously upgrade their professional competence using various opportunities available.',
                gly12:'The risk of glaucoma rises with your age. Some other risk factors are increased eye pressure, family history, conditions such as diabetes or high or low blood pressure, thyroid disorder, eye injuries, certain medications, high refractive errors, and use of steroids.',
                gly23:'Typical symptoms associated with glaucoma include Sudden decrease or transient loss of vision ,Intermittently seeing rainbow coloured halos, Double vision, Intractable headaches,Pupillary abnormalities such as a difference in the size of the pupils',
                 gly24:'Glaucoma is usually treated by lowering your intraocular pressure. Treatment may include prescription eye drops, laser treatment, oral medications, surgery, or a combination of these, depending upon the severity of your condition.',
                gly5:'Pediatric Glaucoma Surgery',
                gly55:'Glaucoma surgery performed on children is known as pediatric glaucoma surgery. External Trabeculotomy and goniotomy are the most common forms of pediatric glaucoma surgery.',
                gly6:'OCT for Ganglion Cell Complex and RNFL',
                gly66:'Optical coherence tomography (OCT) is a non-contact optical technique that permits imaging and measurement of the Ganglion Cell Complex and retinal nerve fiber layer (RNFL)',
                gly7:'Goldmann Applanation Tonometry' ,
                gly77:'This is one of the most accurate optical techniques used to measure intraocular pressure.',
                //
                //cornea
                cor1:'Cornea Treatment',
                corpara1:'We has a team of the finest eye doctors in varanasi dedicated to cornea treatment. Our range of Cornea services includes Cornea abrasion treatment, cornea ulcer treatment, cornea surgery, and more.',
                cor12:'Common causes for Cornea diseases include infections, trauma, injuries, exposure to toxic chemicals, dystrophies and degenerative disorders, autoimmune disorders, ectasia (thinning), nutritional deficiencies, allergies, benign or malignant tumors, common eye conditions like dry eye, glaucoma, etc.',
                cor23:'Signs of Cornea disease or injury include pain, discharge, blurred vision, tearing, redness, extreme sensitivity to light, and Cornea scarring',
                 cor24:'Cornea conditions can be treated with eye patches, bandage contact lenses, prescription eye drops, or pills. Advanced Cornea diseases like Cornea degeneration, is treated with a cornea transplant surgery or laser treatment. Keratoconus, a condition that causes the cornea shape to be distorted, is often managed with special contact lenses. Autoimmune disorders are tackled by treating the core disease. We at Netralayam offer the most innovative and advanced Cornea treatments at our hospital.',
                cor5:'Penetrating Keratoplasty (PK):',
                cor55:'This full-thickness transplant procedure surgically removes the damaged or diseased portion of the cornea, followed by the implantation of healthy tissues from a donated human cornea.',
                cor6:'Therapeutic Penetrating Keratoplasty (TPK): ',
                cor66:'Therapeutic penetrating keratoplasty (TKP) is recommended for severe fungal keratitis to stop the infection, avoid complications, and preserve the globe integrity.',
                cor7:'Hard Contact Lens (RGP & Scleral):' ,
                cor77:'Rigid Gas Permeable (RGP) contact lens can improve the visual acuity in patients with keratoconus and manage complications after a Cornea transplant. Scleral lenses efficiently treat Cornea conditions because instead of contacting the cornea, this lens rests its weight on the sclera (eye-white) and vaults over the cornea.',
                //
                ref1:'Photorefractive keratectomy (PRK)',
                ref111:'With Photorefractive keratectomy (PRK), the eye surgeon removes the top layer of the cornea, called the epithelium. The lasers will then be used to reshape the other layers of the cornea and fix the irregular curvature in the eye.',
                ret1:'A damaged retina can lead to visual disturbances, which, if left untreated, can cause vision loss. At Kashinetralaya, we offer comprehensive Retinal service with cutting-edge technology and painless procedures. Our Retinal surgeons are highly-competent in diagnosing and treating retinal diseases, assuring timely and personalized medical and surgical care for our patients.',
                ret2:'Retinal Laser',
                ret21:'The retinal laser treats macular edema and small breaks in the retina. Macular edema (swelling) is caused due to bleeding of diseased blood vessels that supply blood to the retina, causing blurred vision and severe vision loss. Advanced diabetes, extreme nearsightedness, eye injury, and aging can cause retinal tears.',
                reth2:'Medical and Surgical Management for Diabetic Retinopathy',
                ret3:'Diabetic retinopathy occurs when diabetes damages the retinal blood vessels.It is classified into:',
                ret31:'Non-Proliferative Diabetic Retinopathy:',
                ret32:'Causes microaneurysms, exudates, retinal hemorrhages, and retinal swelling. This is the early phase of diabetic retinopathy.',
                ret33:'Proliferative Diabetic Retinopathy:',
                ret34:'Causes the growth of new, abnormal blood vessels that can cause large vitreous hemorrhages and vision loss. This is an advanced phase of diabetic retinopathy.',
                ret35:'Diabetic retinopathy is treated with laser, eye injections, and surgery. Surgery can prevent further vascular alterations and retain vision.',
                reth3:'ARMD Treatment',
                ret41:'Age-related macular degeneration (ARMD) often occurs in people aged over 50 and causes blurred or loss of central vision. It is classified into:',
                ret42:'Dry Macular Degeneration (Non-Neovascular):',
                ret43:'This is caused by the aging and thinning of macular tissues. It causes yellowish spots in and around the macula, deteriorating macular tissues.',
                ret44:'Wet Macular Degeneration (Neovascular):',
                ret45:'This causes the growth of new blood vessels under the retina, causing blood and fluid leakage and permanent damage to the retina if untreated.',
                ret46:'ARMD can be treated with laser and eye injections.',
                ALLrr:'All Rights Reserved.',
                privacypolicy:'Privacy Policy'
} 