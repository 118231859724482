<template>
    <footer class="allfoot">
        <div class="foot">
            <div style="padding: 20px;">
                <img alt="" src="../assets/Icons/KN-Logo.png" class="footlogo">
            </div>
            <div class="link">
            <ul class="navlink">
                <li><a href="/">{{$t('Homemsg')}}</a></li>
                <li><a href="/#care">{{ $t('Servicesmsg') }}</a></li>
                <!-- <li><a href="">{{$t('OurDoctormsg')}}</a></li> -->
                <li><a href="/#about">{{$t('Aboutmsg')}}</a></li>
                <li><a href="/">{{$t("BookanAppointmentmsg")}}</a></li>

            </ul>
        </div>
        <div class="location">
            <img src="../assets/Icons/locationb.png" alt="">
            <a href="https://maps.app.goo.gl/kmLtn8Tya1vqtkDm7">{{$t('add1')}}<br> {{$t('add2')}} <br>{{$t('add3')}}</a>

        </div>
        <div class="connect">
            <a :href="thriber"><img src="../assets/Icons/ThriberXlogo.png" style="width: 30%;"></a>
            <p>{{$t('connect')}}</p>
            <a href="https://wa.me/916307261100"><img src="../assets/Icons/Whatsapplogo1.png" alt="" class="whatsapp"></a>
            <a :href="'mailto:' + emailAddress"><img src="../assets/Icons/mail.png" alt="" class="mail"></a>
            <a :href="'tel:' + phoneNumber"><img src="../assets/Icons/phone.png" alt="" class=""></a>
        </div>
    </div>
    <h3 class="underline">{{$t('disclaim')}}</h3>
    <br>
    <p class="disclaimerms">{{ $t('disclaimsg1') }}<br>{{ $t('disclaimsg2') }}</p>
    <br>
    <p>&copy;2023 | {{$t('brand_name')}} | {{ $t('ALLrr') }}<RouterLink to="/privacypolicy" class="privacy">{{ $t('privacypolicy') }}</RouterLink></p>
    <a :href="thriber" style="text-decoration: none; color: var(--whitecolor);">Powered by ThriberX</a>
    </footer>
</template>

<script>
export default{
    name:'FooterSection',
    data(){
        return{
            phoneNumber: '6307261100',
        emailAddress: 'info@kashinetralaya.com',
        thriber:'https://www.thriber.com/'
        };
    }

}
</script>


<style>

    .allfoot{
        background-color: var(--bluecolor);
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }


    .foot{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        justify-content: space-around;
        background-color: var(--bluecolor);
        align-items: center;
        padding: 20px;
    }

    .navlink{
        text-align: start;
        list-style: none;
        margin: 0;
        padding: 20px;
    }

    .link li a{
        
        text-decoration: none;
        color: var(--whitecolor);
    }

    .allfoot ,.location p{
        color: var(--secondwhite);
    }

    .location {
    display: flex;
    align-items: flex-start;
    text-align: start;
    gap: 10px;
    }
    .location img{
        background-color: var(--whitecolor);
        border-radius: 20px;
    }
    .location a{
        text-decoration: none;
        margin: 1px;
        color: var(--whitecolor);
    }

    .connect{
        color: var(--whitecolor);
        padding: 20px;
    }
    
    .connect img{
        max-width: 50%;
        padding: 6px;
    }

    .underline{
        text-decoration: underline;
    }

    .privacy{
        text-decoration: none;
        color: var(--whitecolor);
    }

    .footlogo{
        width: 300px;
    }
    @media (max-width: 1000px) {
        .foot{
            display: grid;
            grid-template-columns: 2fr 2fr;
        }
        .navlink{
        text-align: center;
    }

    .location{
        justify-content: center;
        text-align: center;
    }

    .disclaimerms{
        text-align: start;
        padding: 10px;
    }
    }
    @media (max-width: 600px) {
    .foot{
        display: block;
        gap: 25px;
    }
    .navlink{
        text-align: center;
    }

    .location{
        justify-content: center;
    }

    }
</style>
