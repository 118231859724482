<template>
    <!-- <Navbar/> -->
    <section id="specsremove">

        <div class="removepg">
            <h1 class="hero_head"> {{$t('spc')}}</h1>
            <h2 class="head2">{{ $t('Option1') }}</h2>
            <div class="carousel-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Bb8bnjnEM00?si=oWeOvxqePC58BwSy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <br>
                <div class="landservice">
                    <img src="../assets/Icons/clock.png" alt="">
                    <p>{{$t('hourservice')}}</p>
                </div>
            </div>
            <div class="contentall">

<h2>{{ $t('lasikh1') }}</h2>
<p class="spremove">{{$t("lasik1")}} <br><br>{{ $t('lasik11') }}
</p>
<h2>
{{ $t('lasikh2') }}</h2>
<p class="spremove">{{ $t('lasik2') }}<br><br><span style="color: var(--bluecolor); font-size:20px;">{{ $t('lasik21') }}<br><br>{{ $t('lasik22') }}<br><br>{{ $t('lasik23') }}<br><br>{{ $t('lasik24') }}<br><br>{{ $t('lasik25') }}<br><br>{{ $t('lasik26') }}</span></p>
<h2>{{ $t('lasikh3') }}</h2>
<p class="spremove">{{ $t('lasik3') }}<br><br><span style="color: var(--bluecolor); font-size:20px;">{{ $t('lasik31') }}</span><br><br> {{ $t('lasik311') }}</p>
<p class="spremove"><span style="color: var(--bluecolor); font-size:20px;">{{ $t('lasik32') }}</span><br><br> {{ $t('lasik322') }}</p>
<p class="spremove"><span style="color: var(--bluecolor); font-size:20px;">{{ $t('lasik33') }}</span><br><br> {{ $t('lasik333') }}</p>
<p class="spremove"><span style="color: var(--bluecolor); font-size:20px;">{{ $t('lasik34') }}</span><br><br> {{ $t('lasik344') }}</p>
<h2>{{ $t('lasikh4') }}</h2>
<p class="spremove"><span style="color: var(--bluecolor); font-size:20px;">{{ $t('lasik41') }}</span> {{ $t('lasik411') }}</p>
<p class="spremove">{{ $t('lasik9') }}<a :href="'https://maps.app.goo.gl/kmLtn8Tya1vqtkDm7'">{{ $t('lasik99') }}</a>{{ $t('lasik999') }}<a href="/">{{ $t('lasik9999') }}</a>{{ $t('lasik99999') }}</p>


</div>
    </section>
</template>

<script>
export default{
    name:'SpecsremoveSection',
    
};
</script>

<style>

.specsremove{
    font-family: 'Poppins',sans-serif;
}

.removepg{
  padding: 40px;
}

.specr{
  height: 50%;
  width: 60%;
}

.carousel-container{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.head2{
    color: var(--bluecolor);
    font-size: 2rem;
    padding-left:40px;
}

.spremove{
    font-size: 20px;
}

.landservice{
        align-items: center;
        display: flex;
        background-color: var(--lightcolor);
        border-radius: 28px;
        width: 220px;
       }

    .landservice p{
        text-align: center;
        padding: 20px 0px;
    }

    @media (max-width: 650px) {
        /* .carousel-container{
            padding: 10px;
            width: 330px;
            height: 450px;
            margin: 1px;
        } */

        iframe{
            width: 340px; 
            height: 280px;
            
        }
    }
</style>