<template>
    <!-- <Navbar/> -->
    <section id="corntransplant">

        <div class="removepg">
            <h1 class="hero_head"> {{$t('spc')}}</h1>
            <h2 class="head2">{{ $t('Option6') }}</h2>
            <div class="carousel-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/6TXwBwVh_V8?si=KCgljzC0i33RadUW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="landservice">
                    <img src="../assets/Icons/clock.png" alt="">
                    <p>{{$t('hourservice')}}</p>
                </div>
            </div>
            <div class="contentall">

                <h2>{{ $t('cor1') }}</h2>
                <p class="spremove">{{$t("corpara1")}} 
              </p>
              <h2>
                {{ $t('catsurgh2') }}</h2>
              <p class="spremove">
              {{$t("cor12")}}</p>
              <h2>{{ $t('catsurgh3') }}</h2>
              <p class="spremove">{{$t('cor23')}}</p>
              <h2>{{ $t('catsurgh4') }}</h2>
              <p class="spremove">{{$t('cor24')}}</p>
              <h2>{{ $t('catsurgh5') }}</h2>
              <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('cor5') }}</span> {{ $t('cor55') }}</p>
          
              <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('cor6') }}</span> {{ $t('cor66') }}</p>
              
              <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('cor7') }}</span> {{ $t('cor77') }}</p>
              
       
              <p class="spremove">{{ $t('cat9') }}<a target="_blank" :href="'https://maps.app.goo.gl/kmLtn8Tya1vqtkDm7'">{{ $t('cat99') }}</a>{{ $t('cat999') }}<a href="/">{{ $t('cat9999') }}</a>{{ $t('cat99999') }}</p>
              
              
            </div>
        <!-- </div> -->
    </section>
    <!-- <Footer/> -->
</template>

<script>
export default{
    name:'SpecsremoveSection',
};
</script>

<style>

.specr{
  height: 50%;
  width: 60%;
}

.removepg{
  padding: 40px;
}

.head2{
    color: var(--bluecolor);
    font-size: 2rem;
    padding-left:40px;
}

.spremove{
    font-size: 20px;
}

.landservice{
        align-items: center;
        display: flex;
        background-color: var(--lightcolor);
        border-radius: 28px;
        width: 220px;
       }

    .landservice p{
        text-align: center;
        padding: 20px 0px;
    }
</style>