<template>
    <section id="care" class="special_eye">
        <h1 class="hero_head"> {{$t('spc')}}</h1>
        <div class="grid_fom">
           <div class=" item item--1"> 
            <h2 class="head">{{$t('Option1')}}</h2> 
            <h3 class="head-2"> {{$t('Option12')}}</h3>
            <h3 class="head-3"> {{$t('Option13')}} </h3>
            <ul class="list">
                <li>{{$t('Option14')}}</li>
                <li>{{$t('Option15')}}</li>
            </ul>
            <RouterLink to="/ContourVision" class="read">{{$t('read')}}</RouterLink>
        </div>
           <div class="item item--2">
            <h2 class="head"> {{$t('Option2')}}</h2>
            <h3 class="head-2">{{$t('Option21')}} </h3>
            <h3 class="head-3"> {{$t('Option22')}}  </h3>
            <ul class="list">
                <li> {{$t('Option23')}}</li>
                <li>{{$t('Option24')}}</li>
            </ul>
            <RouterLink to="/CataractSurgery" class="read">{{$t('read')}}</RouterLink>
            
        </div>
           <div class="item item--3"> 
            <h2 class="head"> {{$t('Option3')}}</h2>
            <h3 class="head-2"> {{$t('Option31')}}</h3>
            <h3 class="head-3"> {{$t('Option32')}} </h3>
            <ul class="list">
                <li>{{$t('Option33')}}</li>
                <li>{{$t('Option34')}}</li>
            </ul>
            <RouterLink to="/ReferactiveSolution" class="read">{{$t('read')}}</RouterLink>
                
        </div>
           <div class="item item--4"> 
            <h2 class="head">{{$t('Option4')}}</h2>
            <h3 class="head-2"> {{$t('Option41')}} </h3>
            <h3 class="head-3"> {{$t('Option42')}} </h3>
            <ul class="list">
                <li>{{$t('Option43')}}</li>
                <li>{{$t('Option44')}}</li>
            </ul>
            <RouterLink to="/RetinalCare" class="read">{{$t('read')}}</RouterLink>
            
        </div>
           <div class="item item--5">
            <h2 class="head">{{$t('Option5')}}</h2>
            <h3 class="head-2"> {{$t('Option51')}} </h3>
            <h3 class="head-3"> {{$t('Option52')}} </h3>
            <ul class="list">
                <li>{{$t('Option53')}}</li>
                <li>{{$t('Option54')}}</li>
            </ul>
            <RouterLink to="/GlaucomaManagement" class="read">{{$t('read')}}</RouterLink>
                
        </div>
           <div class="item item--6"> 
            <h2 class="head">{{$t('Option6')}}</h2>
            <h3 class="head-2"> {{$t('Option61')}} </h3>
            <h3 class="head-3">{{$t('Option62')}}</h3>
            <ul class="list">
                <li>{{$t('Option63')}}</li>
                <li>{{$t('Option64')}}</li>
            </ul>
            <RouterLink to="/CornealTransplant" class="read">{{$t('read')}}</RouterLink>
        </div>
        </div>
        </section>
</template>

<script>
import { RouterLink } from 'vue-router';

export default{
    name: 'SpeyecareSection',
    components: { RouterLink }
}
</script>

<style>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.special_eye{
    width: 100%;
    height: 100%;
    background: var(--eightwhite);
    font-family: 'Montserrat',sans-serif;
}
.hero_head{
    text-align: center;
    padding: 1rem;
    font-size: 4.5rem;
    font-weight: 600;
    color: var(--bluecolor);
}
.grid_fom{
padding: 1rem 1rem;
 display: grid;
 grid-template-rows: 1fr 1fr;
 grid-template-columns: 1fr 1fr 1fr;
 grid-gap: 3rem;
 justify-content: center;
}
.item{
background-color: var(--seventhwhite);
position: relative;
box-shadow: 0px 4px 4px 0px var(--sixthblack);
border-radius: 1.5rem;
height: auto;

}
.head{
    text-align: center;
    color: var(--seventhwhite);
    border-radius: 1.5rem 1.5rem 0px 0px;
    background: var(--bluecolor);
    padding: 10px;
    font-size: 2rem;
    height: auto;
}
.head-2{
    text-align: center;
    color: var(--blackcolor);
    padding: 1rem;
    font-size: 1.5rem;
    height: auto;
}
.head-3{
    text-align: center;
    color: var(--blackcolor);
    font-size: 1.5rem;
    padding: 10px;
    height: auto;
}
.list{
    text-align: center;
    list-style: none;
    font-size: 1.5rem;
    padding:10px 10px 4rem 10px ;
    height: auto;
}

 .read {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 28px;
    transition: 0.5s;
    color: var(--seventhwhite);
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
    background: var(--bluecolor);
    bottom:0;
    height: auto;
}
a:link { 
    text-decoration: none; 
}
.item--1{
    background-color: var(--thirdwhite);
}
.item--2{
    background-color: var(--thirdwhite);
}
.item--3{
    background-color: var(--thirdwhite);
}
.item--4{
    background-color: var(--thirdwhite);
}
.item--5{

    background-color: var(--thirdwhite);
}
.item--6{
    background-color:var(--thirdwhite);
}
@media (max-width:1000px) {
    .grid_fom{
        grid-template-columns: repeat(auto-fit, minmax(25rem,15rem));
        } 
    
}
@media (max-width:600px){
    .grid_fom{
        grid-template-columns: repeat(auto-fit, minmax(20rem,9rem));
        }
   
    .hero_head{
        font-size:2.5rem;
    }
    .head{
         font-size:2rem;
    }
    .head-2{
       font-size: 1.5rem;
    }
    .head-3{
   font-size: 1.5rem;
    }
    .list{
        font-size: 1rem;
    }
   
    @media (max-width:298px){
        .grid_fom{
        grid-template-columns: repeat(auto-fit, minmax(10rem,9rem));
        flex-wrap: wrap;
        }
.head{
         font-size:1.5rem;
    }
    .head-2{
        padding: 0rem;
       font-size: 1rem;
    }
    .head-3{
   font-size: 1rem;
    }
    .read{
        font-size: 0.51rem;
    }
    }
}
</style>