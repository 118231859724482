<template>
    <section>
        <div class="doctor">
            <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
            <div class="doctordiv">
                <div>
                    <img src="../assets/Icons/DrPrakashParimal.png">
                </div>
                <div class="doctordetail">
                    <h1>Dr. Prakash Parimal</h1>
                    <!-- <h2>Founder</h2> -->
                    <hr>
                    <p class="degree">D.O, D.N.B, F.M.R.F</p>
                    <p>Paediatric Ophthalmologist, Neuro Ophthalmologist and Squint Surgeon</p>
                </div>
            </div>
            <div class="biography">
                <p>Dr. Prakash Parimal is a highly accomplished medical professional with an extensive background in ophthalmology. As a Pediatric Ophthalmologist, he specializes in the diagnosis and treatment of eye conditions in children, providing crucial care for young patients to ensure their visual health and wellbeing. His expertise extends to the field of Neuro-Ophthalmology, where he deals with complex cases related to the interactions between the visual system and the brain. Dr. Parimal's exceptional skills and experience in this area make him a trusted specialist in handling conditions that require a deep understanding of the intricate connections between the eyes and the nervous system. Furthermore, he is a renowned Squint Surgeon, focusing on the correction of strabismus or squint, a condition in which the eyes do not align properly. His proficiency in these multiple areas of ophthalmology underscores his commitment to delivering comprehensive eye care and improving the vision and quality of life for his patients.<br><br>

With a D.O. (Doctor of Osteopathic Medicine), D.N.B. (Diplomate of National Board), and F.M.R.F. (Fellow in Medical Retina and Vitreo-Retinal Surgery) to his name, Dr. Parimal's educational background and clinical training are a testament to his dedication to excellence in the field of ophthalmology. His diverse skill set and broad knowledge base enable him to provide a holistic approach to eye care, making him a respected figure among his peers and a valuable resource for patients seeking specialized ophthalmologic care. Dr. Parimal's commitment to advancing the field of pediatric ophthalmology, neuro-ophthalmology, and squint surgery is evidenced by his continuous pursuit of knowledge, innovation in patient care, and dedication to improving the lives of those he serves.</p>
            </div>
            <div class="minidoctordetail">
                <RouterLink to="/DrDikshaSareen">
                        <div class="minidoctor"> 
                            <img src="../assets/Icons/DrDikshaSareen.png">
                            <div class="minidoctorsec">
                                <h2>Dr. Diksha Sareen </h2>
                                <p class="degrees">M.B.B.S, M.S, D.N.B (Ophth) FLVPEI</p>
                                <p>Cornea Specialist</p>
                                <RouterLink to="/DrDikshaSareen" class="router">
                                    <button> Know More</button>
                                </RouterLink>
                            </div>
                        </div>
                    </RouterLink>
                    <RouterLink to="/DrShantanuGupta">
                <div class="minidoctor"> 
                    <img src="../assets/Icons/DrShantanuGupta.png">
                    <div class="minidoctorsec">
                        <h2>Dr. Shantanu Gupta </h2>
                        <p class="degrees">M.B.B.S, M.S(MAMC,Delhi) D.N.B, F.I.C.O(UK)</p>
                        <p>Medical Retina Surgeon</p>
                        <RouterLink to="/DrShantanuGupta" class="router">
                            <button> Know More</button>
                        </RouterLink>
                    </div>
                </div>
            </RouterLink>
            </div>
        </div>
    </section>
</template>

<script>
import { RouterLink } from 'vue-router';
    export default{
        name:'DoctorPrakashSection',
        components:{ RouterLink}
    }
</script>



<style>

/* 
    .doctor{
        background-image: url('../assets/Bg Images/About Us BG img.png');
        background-size: cover;
        background-position: center;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-family: sans-serif;
    }

    .doctordiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px;
    }

    .doctordetail{
        text-align: start;
        padding: 20px;
    }

    .doctordetail hr{
        width: 520px;
        padding: 2px;
        border: 0;
        border-radius: 10px;
        background-color: var(--bluecolor);
    }

    .doctordetail h1{
        font-size: 3rem;
        font-family: 'Montserrat';
        color: var(--bluecolor);
        padding: 10px;
    }

    .doctordetail h2{
        font-size: 2rem;
        padding: 10px;
    }

    .doctordetail p{
        padding: 10px;
        color: red;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .doctordetail .router{
        padding: 20px 10px 10px 10px;
    }

    .doctordetail button{
        font-size: 1rem;
        padding: 15px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .biography{
        background-color: var(--bluecolor);
        text-align: start;
        padding: 40px 100px;
        color: var(--whitecolor);
        margin: 0px 150px 0px 150px;
        border-radius: 20px;
    }    


    .biography h2{
         padding: 10px;
        font-size: 2rem;
    }

    .biography p{
        padding: 40px 10px;
        font-family: 'Nunito';
        font-size: 1.5rem;
    }

    .minidoctordetail{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 20px   ;
    }
    
    .minidoctor{
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: var(--whitecolor);
        padding: 10px;
        box-shadow: 20px 20px 30px var(--thirdblack);
    }

    .minidoctorsec{
        display: flex;
        flex-direction: column;
    }

    .minidoctor img{
        width: 30%;
    }

    .minidoctorsec h2{
        color: var(--bluecolor);
        padding: 10px;
    }

    .minidoctorsec p{
        color: var(--blackcolor);
    }

    .minidoctorsec button{
        font-size: 1rem;
        padding: 15px 40px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .minidoctorsec .router{
        padding: 10px ;
    } */

</style>