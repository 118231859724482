<template>
    <section>
        <div class="doctor">
            <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
            <div class="doctordiv">
                <div>
                    <img src="../assets/Icons/Dr.SomaiyaSultana.png" >
                </div>
                <div class="doctordetail">
                    <h1>Dr. Somaiya Sultana</h1>
                    <hr>
                    <p class="degree">M.B.B.S, D.N.B (Ophth)</p>
                    <p>Glaucoma and Cornea Specialist</p>
                </div>
            </div>
            <div class="biography">
                <p>Dr. Somaiya Sultana, a highly skilled ophthalmologist with an exceptional academic and clinical background, specializes in glaucoma and medical cornea care. She completed her MBBS at National Medical College, Birgunj, in 2015, demonstrating her dedication to the medical field. Her pursuit of excellence continued with a DNB in Ophthalmology from the National Board of Examination, Delhi, completed at Prakash Netra Kendra, Lucknow, in 2021.<br><br>
                    Dr. Sultana further refined her expertise by completing a long-term fellowship in glaucoma and medical cornea at Prakash Netra Kendra in May 2023. She also holds a fellowship in Small Incision Cataract Surgery (SICS) and Phacoemulsification from the Susrut Eye Foundation and Research Centre, Kolkata, completed in October 2023.<br><br>
                    With a solid foundation in both academic and clinical practice, Dr. Sultana is committed to delivering comprehensive eye care. Her dedication to improving visual health and patient outcomes is reflected in her specialized training and her ongoing commitment to excellence in ophthalmology.


                </p>
            </div>
            <div class="minidoctordetail">
                <RouterLink to="/DrAbhishekDixit">
                    <div class="minidoctor"> 
                        <img src="../assets/Icons/DrAbhishekDixit.png" alt="Dr. Abhishek Dixit">
                        <div class="minidoctorsec">
                            <h2>Dr. Abhishek Dixit</h2>
                            <p class="degrees">M.B.B.S, M.S</p>
                            <p>Vitreo Retina Surgeon</p>
                            <RouterLink to="/DrAbhishekDixit" class="router">
                                <button>Know More</button>
                            </RouterLink>
                        </div>
                    </div>
                </RouterLink>
                <RouterLink to="/DrPrakashParimal">
                    <div class="minidoctor">
                        <img src="../assets/Icons/DrPrakashParimal.png" class="drprakash" alt="Dr. Prakash Parimal">
                        <div class="minidoctorsec">
                            <h2>Dr. Prakash Parimal</h2>
                            <p class="degrees">D.O, D.N.B, F.M.R.F</p>
                            <p>Paediatric Ophthalmologist, Neuro Ophthalmologist and Squint Surgeon</p>
                            <RouterLink to="/DrPrakashParimal" class="router">
                                <button>Know More</button>
                            </RouterLink>
                        </div>
                    </div>
                </RouterLink>
            </div>
        </div>
    </section>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    name: 'DoctorSomaiyaSultanaSection',
    components: { RouterLink }
}
</script>

<style>
/*.doctor {
    background-image: url('../assets/Bg Images/About Us BG img.png');
    background-size: cover;
    background-position: center;
    width: fit-content;
    height: fit-content;
    text-align: center;
    font-family: sans-serif;
}

.doctordiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
}

.doctordetail {
    text-align: start;
    padding: 20px;
}

.doctordetail h1 {
    font-size: 3rem;
    color: #1C70C8;
    padding: 10px;
}

.doctordetail hr {
    margin: 10px 0;
}

.doctordetail .degree {
    font-size: 1.5rem;
    color: #333;
}

.biography {
    background-color: #1C70C8;
    text-align: start;
    padding: 0px 100px;
    color: #fff;
}    

.biography p {
    padding: 40px 10px;
    font-size: 1.5rem;
}

.minidoctordetail {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px;
}
    
.minidoctor {
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 20px 20px 30px rgb(0 0 0 / 31%);
}

.minidoctorsec {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.minidoctor img {
    width: 30%;
}

.minidoctorsec h2 {
    color: #1C70C8;
    padding: 10px 0;
}

.minidoctorsec button {
    font-size: 1rem;
    padding: 15px 50px;
    background-color: #1C70C8;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.minidoctorsec .router {
    padding: 10px 0;
}
    */
</style>
