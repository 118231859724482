<template>
    <section>
        <div class="doctor">
            <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
            <div class="doctordiv">
                <div>
                    <img src="../assets/Icons/DrRSingh.png">
                </div>
                <div class="doctordetail">
                    <h1>Dr. Ranjit Singh</h1>
                    <h2>Founder</h2>
                    <hr>
                    <p class="degree">M.B.B.S, M.S(Ophth)</p>
                    <p>Ophthalmologist</p>
                </div>
            </div>
            <div class="biography">
                <p>Our founder Dr. R. Singh, a distinguished ophthalmologist, completed his MBBS and MS Ophthalmology at the prestigious Banaras Hindu University (BHU) in Varanasi. With a relentless passion for eye care, he embarked on a transformative journey in 1992 by founding Kashi Netralaya Eye Hospital. <br><br>Dr. Singh's unwavering commitment to enhancing vision and eye health has made him a renowned practitioner in the field, and his expertise has touched countless lives. He was a beacon of hope and healing for patients, ensuring a brighter, clearer world through his exceptional medical skills.
                </p>
            </div>
            <div class="minidoctordetail">
                <RouterLink to="/DrShashwatSingh">
                <div class="minidoctor">
                    <img src="../assets/Icons/DrShashwatSingh.png">
                    <div class="minidoctorsec">
                    <h2>Dr. Shashwat Singh</h2>
                    <p class="degrees">M.B.B.S, M.S(Ophth)</p>
                    <p>Phaco-Refractive Surgeon</p>
                    <RouterLink to="/" class="router">
                    <button> Know More</button>
                </RouterLink>
                </div>
            </div>
        </RouterLink>
        <RouterLink to="/DrAbhishekDixit">
                    <div class="minidoctor"> 
                        <img src="../assets/Icons/DrAbhishekDixit.png">
                        <div class="minidoctorsec">
                            <h2>Dr. Abhishek Dixit </h2>
                            <p class="degrees">M.B.B.S, M.S</p>
                            <p>Vitreo Retina Surgeon</p>
                            <RouterLink to="/DrAbhishekDixit" class="router">
                                <button> Know More</button>
                            </RouterLink>
                        </div>
                    </div>
                </RouterLink>   
        
            </div>
        </div>
    </section>
</template>

<script>
import { RouterLink } from 'vue-router';
    export default{
        name:'DoctorRsinghSection',
        components:{ RouterLink}
    }
</script>

<style>

    .doctor{
        background-image: url('../assets/Bg Images/About Us BG img.png');
        background-size: cover;
        background-position: center;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-family: sans-serif;
    }

    .doctordiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px;
    }

    .doctordetail{
        text-align: start;
        padding: 20px;
    }

    .doctordetail hr{
        width: 520px;
        padding: 2px;
        border: 0;
        border-radius: 10px;
        background-color: var(--bluecolor);
    }

    .doctordetail h1{
        font-size: 3rem;
        font-family: 'Montserrat';
        color: var(--bluecolor);
        padding: 10px;
    }

    .doctordetail h2{
        font-size: 2rem;
        padding: 10px;
    }

    .doctordetail .degree{
        padding: 10px;
        color: var(--redcolor);
        font-weight: 600;
        font-size: 1.5rem;
    }

    .doctordetail p{
        padding: 10px;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .doctordetail .router{
        padding: 20px 10px 10px 10px;
    }

    .doctordetail button{
        font-size: 1rem;
        padding: 15px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .biography{
        background-color: var(--bluecolor);
        text-align: start;
        padding: 40px 100px;
        color: var(--whitecolor);
        margin: 0px 150px 0px 150px;
        border-radius: 20px;
    }    


    .biography h2{
         padding: 10px;
        font-size: 2rem;
    }

    .biography p{
        padding: 40px 10px;
        font-family: 'Nunito';
        font-size: 1.5rem;
    }

    .minidoctordetail {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(1,1fr);
    gap: 20px;
    justify-content: space-evenly;
    padding: 40px;
}
    
    .minidoctor{
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: var(--whitecolor);
        padding: 10px;
        box-shadow: 20px 20px 30px var(--thirdblack);
    }

    .minidoctorsec{
        display: flex;
        flex-direction: column;
        text-align: start;
    }

    .minidoctor img{
        width: 33%;
    }

    .minidoctorsec h2{
        color: var(--bluecolor);
        padding: 10px;
    }

    .minidoctorsec p{
        padding: 10px;
        color: var(--blackcolor);
    }

    .minidoctorsec .degrees{
        color: var(--redcolor);
        font-weight: 600;
    }

    .minidoctorsec button{
        font-size: 1rem;
        padding: 15px 50px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .minidoctorsec .router{
        padding: 10px ;
    }

    @media (max-width: 960px) {
        .doctor{
            width: auto;
            background-size:cover;

        }
        .doctordiv{
            flex-direction: column;
        }

        .doctordetail{
            padding: 0px;
        }

        .doctordetail hr{
            width: auto;
        }

        .biography{
            margin-left: 10%;
            margin-right: 10%;
            padding: 10px 25px;
        }

        .minidoctordetail{
            grid-template-rows: none;
        }

        .minidoctor{
            flex-direction: column;
            align-items: center;
        }

        .minidoctorsec{
            text-align: center;
        }
    }

    @media (max-width: 640px) {
        .minidoctordetail{
            grid-template-columns: none;
            grid-template-rows: auto;
        }
    }

</style>