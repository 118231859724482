<template>
    <!-- <Navbar/> -->
    <section id="Retcare">

        <div class="removepg">
            <h1 class="hero_head"> {{$t('spc')}}</h1>
            <h2 class="head2">{{ $t('Option4') }}</h2>
            <div class="carousel-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/yDACxM-yM1w?si=Pmd2dUMkVvRRy5HY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="landservice">
                    <img src="../assets/Icons/clock.png" alt="">
                    <p>{{$t('hourservice')}}</p>
                </div>
            </div>
            <div class="contentall">
                  <h2>{{ $t('Option4') }} {{ $t('Servicesmsg') }}</h2>
                  <p class="spremove">{{$t("ret1")}}</p>
                  <h2>{{ $t('lasikh4') }}</h2>
                  <p class="spremove"><span style="color: var(--bluecolor); font-size:20px;">{{ $t('ret2') }}</span><br><br> {{ $t('ret21') }}</p>
                  <h2>
                    {{ $t('reth2') }}</h2>
                    <p class="spremove">{{ $t('ret3') }}<br><br><span style="color: var(--bluecolor); font-size:20px;">{{ $t('ret31') }}</span></p>
                    <p class="spremove">{{ $t('ret32') }}</p>
                    <p><span style="color: var(--bluecolor); font-size:20px;">{{ $t('ret33') }}</span></p>
                    <p class="spremove">{{ $t('ret34') }}</p>
                    <p class="spremove">{{ $t('ret35') }}</p>
                    <h2>{{ $t('reth3') }}</h2>
                    <p class="spremove">{{ $t('ret41') }}</p>
                    <p><span style="color: var(--bluecolor); font-size:20px;">{{ $t('ret42') }}</span></p>
                    <p class="spremove">{{ $t('ret43') }}</p>
                    <p><span style="color: var(--bluecolor); font-size:20px;">{{ $t('ret44') }}</span></p>
                    <p class="spremove">{{ $t('ret45') }}</p>
                    <p class="spremove">{{ $t('ret46') }}</p>
                    <p class="spremove">{{ $t('lasik9') }}<a :href="'https://maps.app.goo.gl/kmLtn8Tya1vqtkDm7'">{{ $t('lasik99') }}</a>{{ $t('lasik999') }}<a href="/">{{ $t('lasik9999') }}</a>{{ $t('lasik99999') }}</p>
            </div>
    </section>
</template>

<script>
export default{
    name:'SpecsremoveSection',
};
</script>

<style>

.specr{
  height: 50%;
  width: 60%;
}

.removepg{
  padding: 40px;
}

.head2{
    color: var(--bluecolor);
    font-size: 2rem;
    padding-left:40px;
}

.spremove{
    font-size: 20px;
}

.landservice{
        align-items: center;
        display: flex;
        background-color: var(--lightcolor);
        border-radius: 28px;
        width: 220px;
       }

    .landservice p{
        text-align: center;
        padding: 20px 0px;
    }
</style>