<template>
  <nav class="top">
   <marquee class="topmarq"> <div class="topcontainer">
        <a :href="'https://maps.app.goo.gl/kmLtn8Tya1vqtkDm7'"><img src="../assets/Icons/locationb.png" alt=""><p>{{$t("hlv")}}</p></a>      
        <a :href="'tel:' + phoneNumber"><img src="../assets/Icons/phoneb.png" alt="" class="phoneb"><p>+91 7309833833</p></a>
        <a href="#"><img src="../assets/Icons/mailb.png" alt=""><p>{{$t("BookanAppointmentmsg")}}</p></a>
        <a :href="''"><img src="../assets/Icons/glassesb.png"><p>{{$t("trv")}}</p><img src="../assets/Icons/Stars.png" alt="" class=""></a>
      
      </div></marquee>
      <div class="topmarquee">
      <div class="topcontainer">
        <a :href="'https://maps.app.goo.gl/kmLtn8Tya1vqtkDm7'"><img src="../assets/Icons/locationb.png" alt=""><p>{{$t("hlv")}}</p></a>      
        <a :href="'tel:' + phoneNumber"><img src="../assets/Icons/phoneb.png" alt="" class="phoneb"><p>+91 7309833833</p></a>
        <a href="#"><img src="../assets/Icons/mailb.png" alt=""><p>{{$t("BookanAppointmentmsg")}}</p></a>
        <a :href="'https://www.google.com/search?q=kashi+netralaya&sca_esv=576753509&sxsrf=AM9HkKlgPX-g2IFYOgVgEPqUTRFw2A75pw:1698306267490#rlimm=11427200954307246310'"><img src="../assets/Icons/glassesb.png"><p>{{$t("trv")}}</p><img src="../assets/Icons/Stars.png" alt="" class=""></a>
      </div>
      </div>
  </nav>
  <nav class="bottom">
      <img alt="" src="../assets/Icons/menu.png" class="mobile-nav-toggle" @click="toggleNav">
      <img alt="" src="../assets/Icons/KN-Logo.png" class="logo" @click="logo">
    
      <ul :class="['nav-links', { 'active': isNavOpen }]">
      <button class="close-button" v-if="isNavOpen" @click="toggleNav">X</button>
        <li @click="toggleNav"><a href="/">{{$t('Homemsg')}}</a></li>
        <!-- <li><a href="#Services">{{ translate("Servicesmsg") }}</a></li> -->
        <li><div class="dropdown">
        <button class="dropbtn">{{$t("Servicesmsg")}}</button>
          <div class="dropdown-content">
            <a href="/ContourVision">{{$t("Option1")}}</a>
            <a href="/CataractSurgery">{{$t("Option2")}}</a>
            <a href="/ReferactiveSolution">{{$t("Option3")}}</a>
            <a href="/RetinalCare">{{$t("Option4")}}</a>
            <a href="/GlaucomaManagement">{{$t("Option5")}}</a>
            <a href="/CornealTransplant">{{$t("Option6")}}</a>
          </div>
        </div>
      </li>
        <li @click="toggleNav"><a href="/#doctor">{{$t("OurDoctormsg")}}</a></li>
        <li @click="toggleNav"><a href="/#about">{{$t("Aboutmsg")}}</a></li>
        <li><a href="/">{{$t("BookanAppointmentmsg")}}</a></li>
      </ul>

    </nav>
</template>

<script>

  export default {
    name: 'NavbarSection',
    data() {
        return {
            isNavOpen: false,
            phoneNumber: '7309833833',
        };
    },
    methods: {
        toggleNav() {
            this.isNavOpen = !this.isNavOpen;
        },
        logo(){
          window.location.href='/'
        },
    },
  };
</script>

<style>
.bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 11px 24px;
    background-color: var(--whitecolor);
    color: var(--blackcolor);
    position: sticky;
    z-index: 999;
    top: 25px;
    left: 0;
  }
  
  .top{
    background-color: var(--thirdblue);
    display: flex;
    flex-direction: row;
    font-family: 'Montserrat',sans-serif;
    top:0;
    left: 0;
    z-index: 999;
    position: sticky;
    justify-content: center;
  }

  .top .tophide{
    display: none;
    align-items: center;
    gap: 25px;
  }

  .topmarq{
    display: none;
  }

  .top .tophide a{
    text-decoration: none;
    color: var(--blackcolor)E2E;
    display: flex;
    align-items: center;
  }

  .top .topcontainer{
    display: flex;
    align-items: center;
    gap: 25px;
  }
  
  .top .topcontainer a{
    text-decoration: none;
    color: var(--blackcolor)E2E;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .top img{
    max-width: 26%;
  }

  .logo {
    width: 20% ;
  }
 
  .mobile-nav-toggle {
    color: var(--blackcolor);
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }

  ul{
    text-align: center;
    margin-bottom: unset;
  }
  li{
    font-size: 20px ;
  }
  
  .nav-links li a {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    color: var(--blackcolor)E2E;
    transition: color 0.3s ease;
  }
  
  .nav-links li a:hover {
    color: var(--fourthblue);
  }
  .close-button {
    background-color: var(--bluecolor);
    color: var(--seventhwhite);
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    display: none;

}


.overlay.active .close-button {
    display: block;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: var(--whitecolor);
  color: var(--blackcolor)E2E;
  font-size: 20px;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat';
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--fifthwhite);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px var(--fifthblack);
  z-index: 1;
}

.dropdown-content a {
  color: var(--blackcolor);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: var(--sixthwhite);
}

.dropdown:hover .dropdown-content {
  display: block;
}
.btn-primary{
  border-radius: 10px;
  background: linear-gradient(90deg, var(--fifthblue) 0%, var(--fourthblue) 48.96%, var(--secondred) 100%);
  transition: .3s ease;
  padding: 10px 20px;
  color: var(--whitecolor);
  border: 1px solid var(--blackcolor);
  }
  .btn-primary a{
    color: var(--whitecolor);
    font-size: 16px;
    text-decoration: none;
  }
/* Media Query for Responsive Navbar */
  @media (max-width: 935px) {
    .topmarq{
      display: flex;
    }
    .topmarquee{
      display: none;
    }

  }
  @media (max-width: 768px) {

    .logo{
        width: 30%;
    }
    .mobile-nav-toggle {
    display: block;
    }
  
  
    .nav-links {
      display: none;
      flex-direction: column;
      background-color: var(--whitecolor);
      position: absolute;
      top: 10px;
      left: 0;
      height: auto;
      border-radius: 0px 20px 20px 0px;
      width: 80%;
      z-index: 1;
      padding: 49px 19px 350px 0px;
    }
  
    .nav-links.active {
      display: block;
    }
  
    .nav-links li {
      padding: 10px;
      text-align: left;
    }

    .close-button{
      display: block;
      right: 10;
    }

    .top img{
      width: 25%;
    }

  }
  @media (max-width: 600px) {
    

    .tophide{
      display: flex;
    }

    .top .topcontainer{
      gap:40px;
    }

    .nav-links.active{
      height: auto;
    }
  }

    

</style>
