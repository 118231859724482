<template>
   <div class="testimonial">
    <h1>{{$t('testi_head')}}</h1>
    <h2>{{$t('testi_hii')}}</h2>
  <div class="carousel-container">
      <iframe class="carousel-video" :src="currentVideo" frameborder="0" width="270" height="480" allow="autoplay" sandbox="allow-scripts allow-same-origin" allowfullscreen controls=0></iframe>
      <button @click="showPreviousVideo" class="carousel-prev-button"><img src="../assets/Icons/arrow.png"></button>
      <button @click="showNextVideo" class="carousel-next-button"><img src="../assets/Icons/arrow.png"></button>
    </div>
    </div>
    
</template>

<script>
  export default{
    
    name:'TestimonialSection',
    data() {
        return {
          embeddedLinks: [
            "https://www.youtube.com/embed/lBQgiMMekFY?si=ETJJ0UlB_RhmgTtr",
            "https://www.youtube.com/embed/bAnjSRnGywM?si=My47TyES8FxNntBn",
            "https://www.youtube.com/embed/8ycoRvC_wrM?si=3VuXeHPOmYQaOV0V",
          ],
          currentVideoIndex: 0,
        };
      },
      computed: {
        currentVideo() {
          return this.embeddedLinks[this.currentVideoIndex];
        },
      },
      methods: {
        showNextVideo() {
          this.currentVideoIndex = (this.currentVideoIndex + 1) % this.embeddedLinks.length;
        },
        showPreviousVideo() {
          this.currentVideoIndex = (this.currentVideoIndex - 1 + this.embeddedLinks.length) % this.embeddedLinks.length;
        },
      },
    }
  
</script>

<style>
.testimonial{
        background: url('../assets/Bg Images/Testimonials Bg img.png');
        background-position: center;
        background-size: cover;
        height: fit-content;
        text-align: center;
        overflow: hidden;
        width: 100%;
    }

    .testimonial h1{
        color: var(--bluecolor);
        font-family: 'Montserrat',sans-serif; 
        text-align: center;
        padding: 10px;
        font-size: 70px;
        font-weight: 700;   
    }

    .testimonial h2{
      font-weight: 400;
    }

.carousel-container {
  position: relative;
  display: flex;
  text-align: center;
  padding-top: 20px;
  width: 100%;
  height: fit-content;
}

.ndfHFb-c4YZDc-Wrql6b{
  display: none;
  opacity: 0;
}

.carousel-prev-button,
.carousel-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.carousel-prev-button {
  left: 10px;
  background-color: var(--whitecolor);
  padding: 10px;
  font-size: 20px;
  border-radius: 30px;
  transform: rotate(90deg);
}

.carousel-next-button {
  right: 10px;
  background-color: var(--whitecolor);
  padding: 10px;
  font-size: 20px;
  border-radius: 30px;
  transform: rotate(-90deg);
}

@media (max-width: 650px) {
  iframe {
    width: 280px;
    height: 480px;
  }
  
}
</style>