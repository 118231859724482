<template>
    <section>
        <div class="doctor">
            <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
            <div class="doctordiv">
                <div>
                    <img src="../assets/Icons/DrShashwatSingh.png">
                </div>
                <div class="doctordetail">
                    <h1>Dr. Shashwat Singh</h1>
                    <hr>
                    <p class="degree">M.B.B.S, M.S(Ophth)</p>
                    <p>Phaco-Refractive Surgeon</p>
                </div>
            </div>
            <div class="biography">
                <p>Dr. Shashwat Singh completed his MBBS at the esteemed Era's Lucknow Medical College and went on to achieve his MS from Subharti Medical College in 2019. His rigorous training and dedication to the art of ophthalmology have equipped him with the skills necessary to make a difference in the lives of his patients and continue the legacy of his father Dr R singh.<br><br>
                Currently, Dr. Shashwat Singh serves as an executive at Kashi Netralaya, located in the spiritual city of Varanasi. At this cutting-edge eye care facility, he is at the forefront of innovative treatments and technologies, ensuring that patients receive the best possible care for their vision needs.<br><br>
                With a passion for healing and a commitment to improving the lives of those he serves, Dr. Shashwat Singh is your trusted partner on the journey to better eye health. Explore this website to learn more about his qualifications, services, and the difference he can make in your visual world. Your eyes are in safe hands with Dr. Singh.
                </p>
            </div>
            <div class="minidoctordetail">
                <RouterLink to="/DrAbhishekDixit">
                    <div class="minidoctor"> 
                        <img src="../assets/Icons/DrAbhishekDixit.png">
                        <div class="minidoctorsec">
                            <h2>Dr. Abhishek Dixit </h2>
                            <p class="degrees">M.B.B.S, M.S</p>
                            <p>Vitreo Retina Surgeon<br>
                                ‎ </p>
                            <RouterLink to="/DrAbhishekDixit" class="router">
                                <button> Know More</button>
                            </RouterLink>
                        </div>
                    </div>
                </RouterLink>
                <RouterLink to="/DrPrakashParimal">
                <div class="minidoctor">
                    <img src="../assets/Icons/DrPrakashParimal.png" class="drprakash">
                    <div class="minidoctorsec">
                            <h2>Dr. Prakash Parimal</h2>
                            <p class="degrees">D.O, D.N.B, F.M.R.F</p>
                            <p>Paediatric Ophthalmologist, Neuro Ophthalmologist and Squint Surgeon</p>
                            <RouterLink to="/DrPrakashParimal" class="router">
                                <button> Know More</button>
                            </RouterLink>
                        </div>
                    </div>
                </RouterLink>
            </div>
        </div>
    </section>
</template>

<script>
import { RouterLink } from 'vue-router';
    export default{
        name:'DoctorShashwatsinghSection',
        components:{ RouterLink}
    }
</script>

<style>
/* 
    .doctor{
        background-image: url('../assets/Bg Images/About Us BG img.png');
        background-size: cover;
        background-position: center;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-family: sans-serif;
    }

    .doctordiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 40px;
    }

    .doctordetail{
        text-align: start;
        padding: 20px;
    }

    .doctordetail h1{
        font-size: 3rem;
        color: var(--bluecolor);
        padding: 10px;
    }

    .doctordetail h2{
        font-size: 2rem;
        padding: 10px;
    }

    .doctordetail .router{
        padding: 20px 10px 10px 10px;
    }

    .doctordetail button{
        font-size: 1rem;
        padding: 15px 40px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .biography{
        background-color: var(--bluecolor);
        text-align: start;
        padding: 0px 100px;
        color: var(--whitecolor);
    }    

    .biography h2{
         padding: 10px;
        font-size: 2rem;
    }

    .biography p{
        padding: 40px 10px;
        font-size: 1.5rem;
    }

    .minidoctordetail{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 20px   ;
    }
    
    .minidoctor{
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: var(--whitecolor);
        padding: 10px;
        box-shadow: 20px 20px 30px var(--thirdblack);
    }

    .minidoctorsec{
        display: flex;
        flex-direction: column;
    }

    .minidoctor img{
        width: 30%;
    }

    .minidoctorsec h2{
        color: var(--bluecolor);
        padding: 10px;
    }

    .minidoctorsec button{
        font-size: 1rem;
        padding: 15px 50px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .minidoctorsec .router{
        padding: 10px;
    } */

</style>