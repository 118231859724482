<template>
    <section>
        <div class="doctor">
            <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
            <div class="doctordiv">
                <div>
                    <img src="../assets/Icons/DrShantanuGupta.png">
                </div>
                <div class="doctordetail">
                    <h1>Dr. Shantanu Gupta</h1>
                    <!-- <h2>Founder</h2> -->
                    <hr>
                    <p class="degree">M.B.B.S, M.S(MAMC,Delhi) D.N.B, F.I.C.O(UK)  </p>
                    <p>Medical Retina Surgeon</p>
                </div>
            </div>
            <div class="biography">
                <p>Inspired by his father Dr Shantanu entered the field of medicine in the year 2007 after qualifying the All India PMT exam (now NEET) with a brilliant AIR 50. He then completed his MBBS at prestigious University College of Medical Sciences in the year 2012. He went on to crack NEET PG entrance with a AIR 158 to start his journey in Ophthalmology from the prestigious Guru Nanak Eye Centre, Maulana Azad Medical College which is the most sought after superspeciality government facility for training in ophthalmology.
                    <br><br>
                Apart from an excellent rank in NEET PG Dr Shantanu secured AIR 91 in AIIMS PG entrance. His thesis work was awarded as the Best Poster which he had presented in the American Academy Of Ophthalmology, Chicago in 2016. After his post graduation he joined as a senior resident in GNEC, MAMC. There he performed thousands of cataract surgeries. He also got a dedicated training in Cornea and refractive surgery Unit of GNEC where he performed about 150 corneal transplant surgeries, and several other surgeries like LCAT, SLET, Ocular Surface squamous neoplasia excision etc. He also got trained in planning and surgical aspects of LASIK and Phakic IOLs.
                <br><br>
                After his journey in cornea he went on to further pursue 1 year dedicated training in Medical Retina where he performed countless laser procedures, fluorescein angiographies and intravitreal injections. During his senior residency he also cleared the DNB as well as FICO examinations (Fellow of international council of ophthalmology) with merit.</p>
            </div>
            <div class="minidoctordetail">
                <RouterLink to="/DrRSingh">
                        <div class="minidoctor"> 
                            <img src="../assets/Icons/DrRSingh.png">
                            <div class="minidoctorsec">
                                <h2>Dr. Ranjit Singh </h2>
                                <p class="degrees">M.B.B.S, M.S</p>
                                <p>Ophthalmologist</p>
                                <RouterLink to="/DrRSingh" class="router">
                                    <button> Know More</button>
                                </RouterLink>
                            </div>
                        </div>
                    </RouterLink>
                    <RouterLink to="/DrShashwatSingh">
                <div class="minidoctor">
                    <img src="../assets/Icons/DrShashwatSingh.png">
                    <div class="minidoctorsec">
                    <h2>Dr. Shashwat Singh</h2>
                    <p class="degrees">M.B.B.S, M.S(Ophth)</p>
                    <p>Phaco-Refractive Surgeon</p>
                    <RouterLink to="/" class="router">
                    <button> Know More</button>
                </RouterLink>
                </div>
            </div>
        </RouterLink>
            </div>
        </div>
    </section>
</template>

<script>
import { RouterLink } from 'vue-router';
    export default{
        name:'DoctorShantanuSection',
        components:{ RouterLink}
    }
</script>
<!-- 
<style>

    .doctor{
        background-image: url('../assets/Bg Images/About Us BG img.png');
        background-size: cover;
        background-position: center;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-family: sans-serif;
    }

    .doctordiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px;
    }

    .doctordetail{
        text-align: start;
        padding: 20px;
    }

    .doctordetail hr{
        width: 520px;
        padding: 2px;
        border: 0;
        border-radius: 10px;
        background-color: var(--bluecolor);
    }

    .doctordetail h1{
        font-size: 3rem;
        font-family: 'Montserrat';
        color: var(--bluecolor);
        padding: 10px;
    }

    .doctordetail h2{
        font-size: 2rem;
        padding: 10px;
    }

    .doctordetail p{
        padding: 10px;
        color: red;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .doctordetail .router{
        padding: 20px 10px 10px 10px;
    }

    .doctordetail button{
        font-size: 1rem;
        padding: 15px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .biography{
        background-color: var(--bluecolor);
        text-align: start;
        padding: 40px 100px;
        color: var(--whitecolor);
        margin: 0px 150px 0px 150px;
        border-radius: 20px;
    }    


    .biography h2{
         padding: 10px;
        font-size: 2rem;
    }

    .biography p{
        padding: 40px 10px;
        font-family: 'Nunito';
        font-size: 1.5rem;
    }

    .minidoctordetail{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 20px   ;
    }
    
    .minidoctor{
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: var(--whitecolor);
        padding: 10px;
        box-shadow: 20px 20px 30px var(--thirdblack);
    }

    .minidoctorsec{
        display: flex;
        flex-direction: column;
    }

    .minidoctor img{
        width: 30%;
    }

    .minidoctorsec h2{
        color: var(--bluecolor);
        padding: 10px;
    }

    .minidoctorsec p{
        color: var(--blackcolor);
    }

    .minidoctorsec button{
        font-size: 1rem;
        padding: 15px 40px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .minidoctorsec .router{
        padding: 10px ;
    }

</style> -->