<template>
    <section>
        <div class="doctor">
            <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
            <div class="doctordiv">
                <div>
                    <img src="../assets/Icons/DrAbhishekDixit.png">
                </div>
                <div class="doctordetail">
                    <h1>Dr. Abhishek Dixit</h1>
                    <!-- <h2>Founder</h2> -->
                    <hr>
                    <p class="degree">M.B.B.S, M.S</p>
                    <p>Vitero Retinal Surgeon</p>
                </div>
            </div>
            <div class="biography">
                <p>Dr. Abhishek Dixit is a distinguished medical professional with a notable background in ophthalmology. Holding a Master of Surgery (M.S) degree, Dr. Dixit's expertise is evident through his previous role as a consultant at Sitapur Eye Hospital. During his tenure at Sitapur Eye Hospital, he made significant contributions to the field of eye care, showcasing his dedication to improving the visual health of his patients.<br><br>

Dr. Dixit's commitment to advancing his knowledge and skills in ophthalmology led him to undertake fellowships at renowned institutions such as Sankara Nethralaya in Chennai and LV Prasad Eye Institute (LVPEI) in Hyderabad. His specialization in retinopathy of prematurity (ROP) at LVPEI reflects his passion for addressing complex eye conditions, particularly in premature infants. Dr. Abhishek Dixit's academic and professional journey demonstrates his unwavering dedication to providing high-quality eye care and his determination to remain at the forefront of advancements in the field.





</p>
            </div>
            <div class="minidoctordetail">
                <RouterLink to="/DrPrakashParimal">
                <div class="minidoctor">
                    <img src="../assets/Icons/DrPrakashParimal.png" class="drprakash">
                    <div class="minidoctorsec">
                            <h2>Dr. Prakash Parimal</h2>
                            <p class="degrees">D.O, D.N.B, F.M.R.F</p>
                            <p>Paediatric Ophthalmologist, Neuro Ophthalmologist and Squint Surgeon</p>
                            <RouterLink to="/DrPrakashParimal" class="router">
                                <button> Know More</button>
                            </RouterLink>
                        </div>
                    </div>
                </RouterLink>
                <RouterLink to="/DrDikshaSareen">
                        <div class="minidoctor"> 
                            <img src="../assets/Icons/DrDikshaSareen.png">
                            <div class="minidoctorsec">
                                <h2>Dr. Diksha Sareen </h2>
                                <p class="degrees">M.B.B.S, M.S, D.N.B (Ophth) FLVPEI</p>
                                <p>Cornea Specialist<br>
                                    ‎ </p>
                                <RouterLink to="/DrDikshaSareen" class="router">
                                    <button> Know More</button>
                                </RouterLink>
                            </div>
                        </div>
                    </RouterLink>
            </div>
        </div>
    </section>
</template>

<script>
import { RouterLink } from 'vue-router';
    export default{
        name:'DoctorAbhishekSection',
        components:{ RouterLink}
    }
</script>



<style>
    .minidoctor .drprakash{
        width: 32%;
    }
/* 
    .doctor{
        background-image: url('../assets/Bg Images/About Us BG img.png');
        background-size: cover;
        background-position: center;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-family: sans-serif;
    }

    .doctordiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px;
    }

    .doctordetail{
        text-align: start;
        padding: 20px;
    }

    .doctordetail hr{
        width: 520px;
        padding: 2px;
        border: 0;
        border-radius: 10px;
        background-color: var(--bluecolor);
    }

    .doctordetail h1{
        font-size: 3rem;
        font-family: 'Montserrat';
        color: var(--bluecolor);
        padding: 10px;
    }

    .doctordetail h2{
        font-size: 2rem;
        padding: 10px;
    }

    .doctordetail p{
        padding: 10px;
        color: red;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .doctordetail .router{
        padding: 20px 10px 10px 10px;
    }

    .doctordetail button{
        font-size: 1rem;
        padding: 15px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .biography{
        background-color: var(--bluecolor);
        text-align: start;
        padding: 40px 100px;
        color: var(--whitecolor);
        margin: 0px 150px 0px 150px;
        border-radius: 20px;
    }    


    .biography h2{
         padding: 10px;
        font-size: 2rem;
    }

    .biography p{
        padding: 40px 10px;
        font-family: 'Nunito';
        font-size: 1.5rem;
    }

    .minidoctordetail{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 20px   ;
    }
    
    .minidoctor{
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: var(--whitecolor);
        padding: 10px;
        box-shadow: 20px 20px 30px var(--thirdblack);
    }

    .minidoctorsec{
        display: flex;
        flex-direction: column;
    }

    .minidoctor img{
        width: 30%;
    }

    .minidoctorsec h2{
        color: var(--bluecolor);
        padding: 10px;
    }

    .minidoctorsec p{
        color: var(--blackcolor);
    }

    .minidoctorsec button{
        font-size: 1rem;
        padding: 15px 40px;
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        border: 0px solid var(--blackcolor);
        border-radius: 10px;
    }

    .minidoctorsec .router{
        padding: 10px ;
    } */

</style>