<template>
    <section id="catsurg">

        <div class="removepg">
            <h1 class="hero_head"> {{$t('spc')}}</h1>
            <h2 class="head2">{{ $t('Option2') }}</h2>
            <div class="carousel-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/BXEfJNdXlYM?si=IrJySIwuRf6TLape" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      
                </div>
                <div class="landservice">
                    <img src="../assets/Icons/clock.png" alt="">
                    <p>{{$t('hourservice')}}</p>
                </div>
            </div>
            <div class="contentall">

              <h2>{{ $t('catsurgh1') }}</h2>
              <p class="spremove">{{$t("cat1")}} 
            </p>
            <h2>
              {{ $t('catsurgh2') }}</h2>
            <p class="spremove">
            {{$t("cat2")}}</p>
            <h2>{{ $t('catsurgh3') }}</h2>
            <p class="spremove">{{$t('cat3')}}</p>
            <h2>{{ $t('catsurgh4') }}</h2>
            <p class="spremove">{{$t('cat4')}}<br>{{ $t('cat44') }}</p>
            <h2>{{ $t('catsurgh5') }}</h2>
            <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('cat5') }}</span> {{ $t('cat55') }}</p>
        
            <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('cat6') }}</span> {{ $t('cat66') }}</p>
            
            <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('cat7') }}</span> {{ $t('cat77') }}</p>
            
            <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('cat8') }}</span> {{ $t('cat88') }}</p>
            <p class="spremove">{{ $t('cat9') }}<a :href="'https://maps.app.goo.gl/kmLtn8Tya1vqtkDm7'">{{ $t('cat99') }}</a>{{ $t('cat999') }}<a href="/">{{ $t('cat9999') }}</a>{{ $t('cat99999') }}</p>
            
            
          </div>
    </section>
</template>

<script>
export default{
    name:'SpecsremoveSection',
}
</script>

<style>

.specr{
  height: 50%;
  width: 60%;
}

.removepg{
  padding: 40px;
  font-family: 'Montserrat';
}

.head2{
    color: var(--bluecolor);
    font-size: 2rem;
    padding-left:40px;
}

.contentall{
  text-align: start;
  padding: 40px;
}

.contentall h2{
  color: var(--bluecolor);
  padding-bottom: 10px;
}
.spremove{
  color: var(--graycolor);
    font-size: 20px;
    padding-bottom: 10px;
    font-family: 'Nunito';
}

.landservice{
        align-items: center;
        display: flex;
        background-color: var(--lightcolor);
        border-radius: 28px;
        width: 220px;
       }

    .landservice p{
        text-align: start;
        padding: 20px 0px;
    }
</style>