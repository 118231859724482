<template>
    <!-- <Navbar/> -->
    <section id="glaumanage">

        <div class="removepg">
            <h1 class="hero_head"> {{$t('spc')}}</h1>
            <h2 class="head2">{{ $t('Option5') }}</h2>
            <div class="carousel-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/_VtFBnexqm0?si=_O14urNczyIyoW2T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="landservice">
                    <img src="../assets/Icons/clock.png" alt="">
                    <p>{{$t('hourservice')}}</p>
                </div>
            </div>
            <div class="contentall">

              <h2>{{ $t('gly1') }}</h2>
              <p class="spremove">{{$t("glypara1")}} 
            </p>
            <h2>
              {{ $t('catsurgh2') }}</h2>
            <p class="spremove">
            {{$t("gly12")}}</p>
            <h2>{{ $t('catsurgh3') }}</h2>
            <p class="spremove">{{$t('gly23')}}</p>
            <h2>{{ $t('catsurgh4') }}</h2>
            <p class="spremove">{{$t('gly24')}}<br>{{ $t('cat44') }}</p>
            <h2>{{ $t('catsurgh5') }}</h2>
            <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('gly5') }}</span> {{ $t('gly55') }}</p>
        
            <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('gly6') }}</span> {{ $t('gly66') }}</p>
            
            <p class="spremove"><span style="color: var(--bluecolor);">{{ $t('gly7') }}</span> {{ $t('gly77') }}</p>
            
     
            <p class="spremove">{{ $t('cat9') }}<a target="_blank" :href="'https://maps.app.goo.gl/kmLtn8Tya1vqtkDm7'">{{ $t('cat99') }}</a>{{ $t('cat999') }}<a href="/">{{ $t('cat9999') }}</a>{{ $t('cat99999') }}</p>
            
            
          </div>
    </section>
    <!-- <Footer/> -->
</template>

<script>
export default{
    name:'SpecsremoveSection',
    
};
</script>

<style>

.specr{
  height: 50%;
  width: 60%;
}

.removepg{
  padding: 40px;
}

.head2{
    color: var(--bluecolor);
    font-size: 2rem;
    padding-left:40px;
}

.spremove{
    font-size: 20px;
}

.landservice{
        align-items: center;
        display: flex;
        background-color: var(--lightcolor);
        border-radius: 28px;
        width: 220px;
       }

    .landservice p{
        text-align: center;
        padding: 20px 0px;
    }
</style>