<template>
  <div id="app" class="app">
    <div class="image">
        <a href="https://wa.me/916307261100"><img src="./assets/Icons/WhatsappIcon.png" alt="" class="whatsapp"></a>
        <a :href="'mailto:' + emailAddress"><img src="./assets/Icons/MailIcon.png" alt="" class="mail"></a>
        <a :href="'tel:' + phoneNumber"><img src="./assets/Icons/PhoneIcon.png" alt="" class="phone"></a> 
    </div>
    <Navbar />
    <div>
      <select v-model="currentLocale" @change="changeLanguage" class="language-selector">
        <option value="hi" class="language-selector-option">Hindi</option>
        <option value="en" class="language-selector-option">English</option>
      </select>
    </div>
    <router-view></router-view>
    <div class="contactfixed">
      <div class="sizing"><a href="/"><img src="./assets/Icons/mail.png"><p>Book an </p><p>Appointment</p></a></div>
      <div class="sizing"><a :href="'tel:' + 7309833833"><img src="./assets/Icons/phone.png"><p>Call Now</p><p>7309833833</p></a></div>
      <div class="sizing"><a href="https://wa.me/916307261100"><img src="./assets/Icons/Whatsapplogo1.png"><p>Whatsapp</p><p>6307261100</p></a></div>
    </div>
    <Footer/>
  </div>
</template>

<script>

import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
},
  data() {
    return {
      currentSection: 'about',
      currentLocale: 'hi',
      phoneNumber: '7309833833',
        emailAddress: 'info@kashinetralaya.com',
    };
  },
  methods: {
    showSection(section) {
      this.currentSection = section;
    },
    changeLanguage() {
      this.$i18n.locale = this.currentLocale;
    },

  }
}
</script>

<style>
.app{
  font-family: 'Montserrat';
}

.nav {
  display: flex;
  justify-content: center;
  background-color: var(--thirdgray);
  padding: 10px;
}

.nav button {
  background: none;
  border: none;
  color: var(--seventhwhite);
  cursor: pointer;
  margin: 0 10px;
}
.language-selector {
  width: 100px;
  margin-left: 10px;
  font-size: 16px;
  color: var(--whitecolor);
  background-color: var(--bluecolor);
  border-radius: 5px;
  border: 1px solid var(--fourthwhite);
  padding: 10px;
  position: fixed;
  z-index:99;
}

.language-selector-option {
  font-family: sans-serif;
  font-size: 16px;
  color: var(--blackcolor);
  background-color: var(--whitecolor);
  padding: 10px;
  border: 1px solid var(--fourthwhite);
  border-radius: 5px;
}

.language-selector-option:hover {
  background-color: #eee;
}
.nav button:hover {
  text-decoration: underline;
}

.contactfixed{
  display: none;
}

.image{
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 50%;
        right: 0;
        gap: 10px;
        z-index: 10;
    }

    .image a img{
        width: 70%;
    }

    @media (max-width: 935px) {
      .image{
        display: none;
      }
    }

@media (max-width: 640px) {
  .contactfixed {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: var(--bluecolor);
    align-items: center;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    color: var(--whitecolor);
  }

  .sizing{
    padding: 10px;
    border-right: solid 1px var(--whitecolor);
  }

  .sizing a{
    text-decoration: none;
    color: var(--whitecolor);
  }
}

</style>
