<template>
    <section id="doctor">
    <div class="docdiv">
        <h2 class="dochead">{{$t("OurDoctormsg")}}</h2>
        <div class="docheader">

            <!-- <h4>Meet Our Team</h4> -->
            <h1>Best Eye Specialist in Varanasi, Uttar Pradesh</h1>
        </div>
        <div class="rsinghimg">
            <RouterLink to="/DrRSingh" class="rsingha">
            <img src="../assets/Icons/DrRSingh.png">
            <h3>Dr. Ranjit Singh</h3>
            <div class="rdetails">
            <p>Founder</p>
            </div>
        </RouterLink>
        <RouterLink to="/" class="vsingha"> <!-- DrVirbalaSingh -->
            <img src="../assets/Icons/DrVirbalaSingh.png" class="vsinghimg">
            <h3>Virbala Singh</h3>
            <div class="rdetails">
            <p>Director</p>
            </div>
        </RouterLink>

             
        </div>
        <div class="docimg">
            <div class="ssinghimg">
                <RouterLink to="/DrShashwatSingh">
                <img src="../assets/Icons/DrShashwatSingh.png">
                <h3>Dr. Shashwat Singh</h3>
                <p style="color: red;font-weight: 600;">M.B.B.S, M.S. (Ophth)</p>
                <p>Phaco-Refractive Surgeon</p>
            </RouterLink>
            </div>
            <!-- <div class="asinghimg">
                <RouterLink to="/DrAshutoshSingh"> 
                <img src="../assets/Icons/DrAshutoshSingh.png">
                <h3>Dr. Ashutosh Singh</h3>
                <p style="color: red;font-weight: 600;">M.B.B.S, D.O.M.S. (Ophth)</p>
                <p>Phaco-Refractive Surgeon</p>
            </RouterLink>
            </div> -->
            <div class="abhiimg">
                <RouterLink to="/DrAbhishekDixit"> 
                <img src="../assets/Icons/DrAbhishekDixit.png">
                <h3>Dr. Abhishek Dixit</h3>
                <p style="color: red;font-weight: 600;">M.B.B.S, M.S.</p>
                <p>Vitreo Retinal Surgeon</p>
            </RouterLink>
            </div>
            <div class="prakashimg">
                <RouterLink to="/DrPrakashParimal"> 
                <img src="../assets/Icons/DrPrakashParimal.png">
                <h3>Dr. Prakash Parimal</h3>
                <p style="color: red;font-weight: 600;">D.O, D.N.B, F.M.R.F</p>
                <p>Paediatric Ophthalmologist, Neuro Ophthalmologist and Squint Surgeon</p>
            </RouterLink>
            </div>
            <div class="dikshaimg">
                <RouterLink to="/DrDikshaSareen"> 
                <img src="../assets/Icons/DrDikshaSareen.png">
                <h3>Dr. Diksha Sareen</h3>
                <p style="color: red;font-weight: 600;">M.B.B.S, M.S, D.N.B (Ophth) FLVPEI</p>
                <p>Cornea Specialist</p>
            </RouterLink>
            </div>
            <div class="shanuimg"> 
                <RouterLink to="/DrShantanuGupta">
                <img src="../assets/Icons/DrShantanuGupta.png">
                <h3>Dr. Shantanu Gupta</h3>
                <p style="color: red;font-weight: 600;">M.B.B.S, M.S(MAMC, Delhi) D.N.B, FICO(UK)</p>
                <p>Phaco and Medical Retina Specialist</p>
            </RouterLink>
            </div>
            <div class="somaiyaimg"> 
                <RouterLink to="/DrSomaiyaSultana">
                <img src="../assets/Icons/Dr.SomaiyaSultana.png">
                <h3>Dr. Somaiya Sultana </h3>
                <p style="color: red;font-weight: 600;">M.B.B.S, D.N.B (Ophth)</p>
                <p>Glaucoma and Cornea Specialist</p>
            </RouterLink>
            </div>
            
        </div>
    </div>
</section>
</template>

<script>
import { RouterLink } from 'vue-router';
export default{
    name:'DoctorSection',
    components: { RouterLink }
};

</script>

<style>
.docdiv{
    text-align: center;
    width: 100%;
    height: 100%;
    background-image: url("../assets/Bg Images/About Us BG img.png");
    background-size:cover;
    font-family: 'Montserrat', sans-serif;
}

.dochead{
    font-size: 4rem;
    padding: 20px;
    color: var(--bluecolor);
}

.rsinghimg p{
    color: var(--blackcolor);
}

.docheader{
    color: var(--bluecolor);
    text-align: center;
}

.docimg{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
    text-align: center;
    padding-top: 20px;
    padding: 40px 80px;

}

.docimg h3{
    color: var(--bluecolor);
    padding: 10px;

}

.docimg p{
    color: var(--blackcolor);
    padding-bottom: 5px;
}

.rsinghimg{
    padding: 20px;
    color: var(--bluecolor);
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

.rsingha{
    position: relative;
    left: 20%;
}

.vsingha{
    position: relative;
    right: 20%;
}

.rsinghimg .vsinghimg{
    width: 29%;
}

.rsinghimg h3{
    color: var(--bluecolor);
}

.rsinghimg img{
    width: 30%;
}

.ssinghimg img{
    width: 50%;
}

.asinghimg img{
    width: 50%;
}

.dikshaimg img{
    width: 50%;
}

.abhiimg img{
    width: 50%;
}

.shanuimg img{
    width: 50%;
}

.prakashimg{
    width: 332px;
}

.prakashimg img{
    width: 48%;
}

.somaiyaimg img{
    width: 50%;
}


.rdetails {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.rdetails .pimg{
    width: 4%;
}

.RouterLink{
    text-decoration: none;
    color: var(--bluecolor);
}

@media (max-width: 790px) {
    .docimg{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .rsinghimg{
        grid-template-rows: repeat(2,1fr);
        grid-template-columns: none;
        gap: 20px;
    }

    .rsingha{
        position:unset;
        left:0%;
    }

    .vsingha{
        position: unset;
        right: 0%;
    }

    .rsinghimg img{
    width: 74%;
    }

    .rsinghimg .vsinghimg{
        width: 76%;
    }

    .ssinghimg img{
        width: 90%;
    }

    .asinghimg img{
        width: 90%;
    }

    .dikshaimg img{
        width: 90%;
    }

    .abhiimg img{
        width: 90%;
    }

    .shanuimg img{
        width: 90%;
    }

    .prakashimg{
        width: auto;
    }

    .prakashimg img{
    width: 88%;
}
     
    .somaiyaimg img{
        width:90%;
    }

}
</style>