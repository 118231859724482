export default{
        hlv:'वाराणसी में अस्पताल का स्थान',
        trv:"वाराणसी में टॉप रेटेड",
        Homemsg:'होम',
                Servicesmsg:'सेवाएं',
                Option1:'  लेसिक विजन  ',
                Option2:'  मोतियाबिंद ऑपरेशन  ',
                Option3:'  अपवर्तक समाधान  ',
                Option4:'  रेटिना की देखभाल  ',
                Option5:'  ग्लूकोमा प्रबंधन  ',
                Option6:'  कॉर्निया प्रत्यारोपण  ',
                OurDoctormsg:'हमारे डॉक्टर',
                Aboutmsg:'जानकारी',
                BookanAppointmentmsg:'अपॉइंटमेंट लीजिए',
                tcoym:'अपनी आँखों को स्वस्थ और खुश रखें!',
                landmsg:"आपकी सेवा में विशेषज्ञ नेत्र देखभाल के साथ दुनिया को स्पष्ट रूप से देखें।",
                spc:'विशेषज्ञ नेत्र-देखभाल',
                asa:"आयुष्मान योजना उपलब्ध है",
                ehcs:'भूतपूर्व सैनिक अंशदायी स्वास्थ्य योजना',
                eycdmsg:'अपॉइंटमेंट शेड्यूल करने या हमारे साथ इस पर विस्तार से चर्चा करने के लिए नीचे अपने संपर्क दर्ज करें',
                hourservice:'24 घंटे सेवा',
                cataract:'मोतियाबिंद',
                surgery:"ऑपरेशन",
                nbns:'कोई ब्लेड नहीं, कोई सिलाई नहीं',
                npni:'कोई दर्द नहीं, कोई इंजेक्शन नहीं',
                suedo:'केवल आई ड्रॉप के तहत सर्जरी',
                abtmsg1:'डॉ. आर. सिंह द्वारा स्थापित प्रसिद्ध नेत्र चिकित्सालय काशी नेत्रालय में आपका स्वागत है। हम भारत के वाराणसी में स्थित हैं, और अपनी विशेषज्ञ नेत्र देखभाल सेवाओं और उच्च गुणवत्ता वाले उपचार प्रदान करने की प्रतिबद्धता के लिए प्रसिद्ध हैं।',
                abtmsg2:'काशी नेत्रालय में हमारा मुख्य लक्ष्य लोगों की आंखों की समस्याओं में मदद करना और उनकी आंखों को स्वस्थ रखना है। हमारे पास विशिष्ट सेवाओं की एक विस्तृत श्रृंखला प्रदान करने के लिए कुशल डॉक्टरों और उन्नत तकनीक की एक टीम है। इसमें मोतियाबिंद, अपवर्तक समस्याएं, रेटिना संबंधी चिंताएं, ग्लूकोमा और कॉर्निया प्रत्यारोपण जैसे मुद्दों के लिए सर्जरी शामिल हैं। हम विशेष रूप से बच्चों के नेत्र स्वास्थ्य के लिए भी सेवाएँ प्रदान करते हैं।',
                abtmsg3:'हम यह सुनिश्चित करना चाहते हैं कि आपको अपनी आंखों की सर्वोत्तम देखभाल मिले, और हमारी टीम आपकी सहायता के लिए यहां है। इसलिए, यदि आपको आंखों से संबंधित कोई चिंता है, तो बेझिझक हमसे संपर्क करें। आपकी दृष्टि हमारे लिए महत्वपूर्ण है, और हम नेत्र देखभाल में आपका विश्वसनीय भागीदार बनकर सम्मानित महसूस कर रहे हैं। आइए काशी नेत्रालय आएं और आइए हम आपको एक उज्जवल भविष्य देखने में मदद करें।',
                Option12:'चश्मा हटाना',
                Option13:'सबसे तीव्र दृष्टि',
                Option14:'100% ब्लेड-मुक्त चश्मा हटाना',
                Option15:'बढ़ी हुई सुरक्षा',
                add1:'काशी नेत्रालय, 27/ए, भुवनेश्वर नगर कॉलोनी,',
                add2:'टैगोर टाउन, अर्दली बाज़ार, वाराणसी,',
                add3:'उत्तर प्रदेश 221002',
                Option21:'क्रिस्टल स्पष्ट दृष्टि',
                Option22:'शीघ्र एवं प्रभावी मोतियाबिंद हटाना',
                Option23:'जीवंत, स्पष्ट दृष्टि की बहाली',
                Option24:'उन्नत, ब्लेड-मुक्त मोतियाबिंद सर्जरी',
                Option31:'दृश्य स्वतंत्रता',
                Option32:'चश्मे और कॉन्टैक्ट लेंस से मुक्ति',
                Option33:'अनुकूलित अपवर्तक प्रक्रियाएँ',
                Option34:'दृष्टि सुधार में परिशुद्धता और सटीकता',
                Option41:'आपके रेटिना की सुरक्षा',
                Option42:'व्यापक रेटिना निदान',
                Option43:'रेटिना संबंधी विकारों के लिए विशेषज्ञ उपचार',
                Option44:'अपनी दृष्टि और नेत्र स्वास्थ्य का संरक्षण करना',
                Option51:'इंट्राओकुलर दबाव का प्रबंधन',
                Option52:'शीघ्र पता लगाना और निदान करना',
                Option53:'अनुकूलित उपचार योजनाएँ',
                Option54:'विशेषज्ञ देखभाल के माध्यम से दृष्टि हानि को रोकना',
                Option61:'नवीनीकृत दृष्टि',
                Option62:'दृष्टि में सुधार के लिए क्षतिग्रस्त कॉर्निया को बहाल करना',
                Option63:'जीवन बदलने वाली कॉर्निया प्रत्यारोपण प्रक्रियाएं',
                Option64:'कुशल सर्जन और अत्याधुनिक तकनीकें',
                read:'और पढ़ें',
                about_us:'हमारे बारे में',
                getIn_touch:'संपर्क में रहो',
                Submit:'जमा करना',
                call:'हमें कॉल करें',
                discount:'40% छूट',
                placeholder:'आपका नाम*',
                placeholder2:'फ़ोन नंबर*',
                book_now:'अभी बुक करें',
                connect:'हमारे साथ जुड़ें।',
                brand_name:'काशी नेत्रालय नेत्र देखभाल और लेसिक लेजर केंद्र।',
                testi_head:'गुणों का वर्ण-पत्र',
                testi_hii:'पता लगाएं कि हमारी जीवन बदलने वाली नेत्र सर्जरी ने हमारे मूल्यवान रोगियों की दृष्टि और जीवन को कैसे बदल दिया है।',
                disclaim:'अस्वीकरण',
                disclaimsg1:'इस साइट पर दी गई जानकारी को चिकित्सीय सलाह नहीं माना जाना चाहिए। सर्वोत्तम प्रक्रिया के साथ-साथ अपनी आंखों के लिए सबसे उपयुक्त लेंस का निर्धारण करने के लिए कृपया डॉक्टर से परामर्श लें। ',
                disclaimsg2:'परिणाम व्यक्ति दर व्यक्ति अलग-अलग हो सकते हैं और हम सभी के लिए समान परिणाम की गारंटी नहीं देते हैं।',
                cat1:"हमारा लक्ष्य अपने मरीजों को मोतियाबिंद के लिए सर्वोत्तम उपचार प्रदान करना है क्योंकि हम मोतियाबिंद ऑपरेशन के लिए वाराणसी के सर्वश्रेष्ठ नेत्र अस्पतालों में से एक हैं। हमारे पास वाराणसी में सबसे अच्छे मोतियाबिंद सर्जन हैं जो अपने काम में अत्यधिक प्रशिक्षित हैं और उपलब्ध विभिन्न अवसरों का उपयोग करके अपनी पेशेवर क्षमता को लगातार उन्नत करते रहते हैं।",
                cat2:"मोतियाबिंद बुढ़ापे, धूम्रपान, मोटापा, मधुमेह, आघात, विकिरण चिकित्सा, या आंतरिक नेत्र संरचनाओं की सूजन के कारण हो सकता है।",
                cat3:"मोतियाबिंद के लक्षणों में धुंधली दृष्टि, रात में देखने में परेशानी, रंगों को फीका दिखना, चमक के प्रति संवेदनशीलता में वृद्धि, प्रभावित आंख में दोहरी दृष्टि और रोशनी के आसपास प्रभामंडल शामिल हैं।",
                cat4:"मोतियाबिंद के इलाज के लिए सर्जरी की सलाह दी जाती है।",
                cat44:'एक सर्जिकल विधि, जिसे फेकोमल्सीफिकेशन के रूप में जाना जाता है, में लेंस को अलग करने और टुकड़ों को हटाने के लिए अल्ट्रासाउंड तरंगों का उपयोग करना शामिल है। यदि आप वाराणसी में "मेरे निकट मोतियाबिंद सर्जरी के लिए सर्वश्रेष्ठ डॉक्टरों" की तलाश कर रहे हैं, तो काशीनेत्रालय में हम आपकी सहायता के लिए तैयार हैं।',
                cat5:'फोल्डेबल आईओएल के साथ फेकोइमल्सीफिकेशन:',
                cat55:'2.8 मिमी का एक छोटा कॉर्नियल चीरा लगाया जाता है। फिर मोतियाबिंद को अल्ट्रासोनिक फेकोइमल्सीफिकेशन जांच द्वारा हटा दिया जाता है, और आईओएल प्रत्यारोपित किया जाता है।',
                cat6:'मल्टीफ़ोकल आईओएल:',
                cat66:'मल्टीफ़ोकल कॉन्टैक्ट लेंस को चश्मे के बिना दूर और निकट दोनों दृष्टि को सही करने के समाधान के रूप में प्रत्यारोपित किया जाता है। रोगियों के एक छोटे प्रतिशत को कभी-कभी छोटी बिजली की आवश्यकता हो सकती है।',
                cat7:'माइक्रो-कोएक्सियल फेको सर्जरी:',
                cat77:' यह तकनीक चीरे को 2.2 मिमी से कम कर देती है। इस प्रक्रिया में, मानक समाक्षीय छोटी चीरा मोतियाबिंद सर्जरी में उपयोग किए जाने वाले उपकरणों के साथ सूक्ष्म-समाक्षीय फाकोइमल्सीफिकेशन, सिंचाई, आकांक्षा और फाकोइमल्सीफिकेशन किया जाता है। छोटे चीरे लगाते समय इस सर्जरी में मानक समाक्षीय छोटी चीरा मोतियाबिंद सर्जरी के लगभग सभी फायदे हैं। इसलिए, मरीजों को उनकी प्रक्रियाओं के बाद तेजी से घाव भरने, छोटे एसआईए, बेहतर पोस्टऑपरेटिव दृश्य तीक्ष्णता, संक्रमण का कम जोखिम और अधिक स्थिर कॉर्नियल बायोमैकेनिक्स मिलता है।',
                cat8:'प्रीमियम मोतियाबिंद सर्जरी विकल्प:',
                cat88:' हम मोतियाबिंद सर्जरी के लिए मोनो-विज़न, टोरिक आईओएल, एस्फेरिक आईओएल और मल्टीफ़ोकल आईओएल जैसे प्रीमियम विकल्प भी प्रदान करते हैं जो धुंधले क्षेत्रों के बिना दूरी, मध्यवर्ती और निकट कार्यों के लिए दृष्टि को सक्षम करते हैं।',
                cat9:'यदि आप "मेरे निकट मोतियाबिंद सर्जरी डॉक्टर" या "मेरे निकट मोतियाबिंद सर्जन" की तलाश कर रहे हैं,',
                cat99:' काशी नेत्रालय नेत्र देखभाल और लेसिक लेजर केंद्र वाराणसी में ',
                cat999:'या  ',
                cat9999:'क्लिक करें',
                cat99999:' अपॉइंटमेंट के लिए।',
                catsurgh1:'मोतियाबिंद ऑपरेशन',
                catsurgh2:'कारण',
                catsurgh3:'लक्षण',
                catsurgh4:'इलाज',
                catsurgh5:'हमारे द्वारा प्रदान की जाने वाली मोतियाबिंद सेवाएँ',
                lasikh1:'लेसिक विज़न',
                lasik1:'काशीनेत्रालय में, हमारे चिकित्सा विशेषज्ञ हमारे रोगियों को उच्चतम गुणवत्ता वाली नेत्र देखभाल सेवाएँ प्रदान करते हैं। हमारे नेत्र विशेषज्ञ मोतियाबिंद सर्जरी से लेकर अपवर्तक त्रुटि उपचार तक उच्चतम रोगी देखभाल मानक प्रदान करते हैं। अपवर्तक त्रुटि आंख के आकार को संदर्भित करती है जो प्रकाश को सही ढंग से नहीं मोड़ती है। इससे दृष्टि धुंधली हो जाती है। आँख में अपवर्तन के कारण स्पष्ट रूप से देखना कठिन हो सकता है।',
                lasik11:'बुनियादी अपवर्तक त्रुटियों में मायोपिया (निकट दृष्टि दोष), प्रेस्बायोपिया (उम्र के कारण पास की वस्तुओं को देखने में कठिनाई), हाइपरोपिया (दूर दृष्टि दोष) और दृष्टिवैषम्य शामिल हैं।',
                lasikh2:'लक्षण',
                lasikh3:'कारण',
                lasik2:'अपवर्तक त्रुटि के विभिन्न लक्षण हैं:',
                lasik21:'धुंधली दृष्टि',
                lasik22:'चमकदार रोशनी के चारों ओर चकाचौंध या प्रभामंडल देखना',
                lasik23:'देखने में',
                lasik24:'सिर दर्द',
                lasik25:'आंखों पर तनाव (जब आंखें थकी हुई या दर्द महसूस होती हैं)',
                lasik26:'पढ़ते समय या कंप्यूटर देखते समय ध्यान केंद्रित करने में परेशानी होना',
                lasik3:'आँख के अपवर्तन विकारों के कारणों का वर्णन नीचे दिया गया है:',
                lasik31:'निकट दृष्टि दोष:',
                lasik311:'मायोपिया एक सामान्य दृष्टि स्थिति है जहां आप अपने पास की वस्तुओं को स्पष्ट रूप से देख सकते हैं, लेकिन दूर की चीजें अस्पष्ट दिखाई देती हैं। निकटदृष्टि दोष के रूप में भी जाना जाता है, मायोपिया विरासत में मिलता है और अक्सर बचपन में पता चलता है और बढ़ता जाता है।',
                lasik32:'पास का साफ़-साफ़ न दिखना:',
                lasik322:'इसे दूरदर्शिता के रूप में भी जाना जाता है, यह विरासत में भी मिल सकता है। हल्के हाइपरोपिया में दूर की दृष्टि स्पष्ट होती है, जबकि निकट की दृष्टि धुंधली होती है।',
                lasik33:'प्रेसबायोपिया:',
                lasik333:'आँख का लेंस अधिक कठोर हो जाता है और उतनी तेज़ी से और आसानी से नहीं मुड़ता जितना होना चाहिए। इसलिए आंख ठीक से फोकस नहीं कर पाती है और पास की वस्तुओं को स्पष्ट रूप से पढ़ना या देखना मुश्किल हो जाता है। 40 साल की उम्र तक यह ध्यान देने योग्य हो जाता है।',
                lasik34:'दृष्टिवैषम्य:',
                lasik344:'यह आमतौर पर तब होता है जब कॉर्निया, यानी आंख की सामने की सतह, अनियमित आकार या वक्रता वाली होती है। इस विसंगति के परिणामस्वरूप दृष्टि विकृत दर्पण में देखने के समान हो सकती है।',
                lasikh4:'हमारी सेवाएँ',
                lasik41:'लेसिक',
                lasik411:'लेजर-असिस्टेड इन सीटू केराटोमिलेसिस (LASIK) एक ऐसी प्रक्रिया है जिसमें अपवर्तन की कॉर्नियल त्रुटियों को ठीक करने के लिए लेजर तकनीक का उपयोग किया जाता है। ऐसी त्रुटियों के इलाज में यह पूरी तरह से सुरक्षित और दर्द रहित है।',
                lasik9:'यदि आप "मेरे निकट लेजर सर्जरी डॉक्टर" या "मेरे निकट लेजर सर्जन" की तलाश कर रहे हैं,',
                lasik99:' काशी नेत्रालय नेत्र देखभाल और लेसिक लेजर केंद्र वाराणसी में ',
                lasik999:'या  ',
                lasik9999:'क्लिक करें',
                lasik99999:' अपॉइंटमेंट के लिए।',

                 //glyconma
                 gly1:'ग्लूकोमा का इलाज',
                 glypara1:'हमारा लक्ष्य अपने मरीजों को ग्लूकोमा के लिए सर्वोत्तम उपचार प्रदान करना है क्योंकि हम ग्लूकोमा ऑपरेशन के लिए वाराणसी में सबसे अच्छे नेत्र अस्पतालों में से एक हैं। हमारे पास वाराणसी में सबसे अच्छे मोतियाबिंद सर्जन हैं जो अपने काम में अत्यधिक प्रशिक्षित हैं और उपलब्ध विभिन्न अवसरों का उपयोग करके लगातार अपनी पेशेवर क्षमता को उन्नत करते हैं।', 
                 gly12:'आपकी उम्र के साथ ग्लूकोमा का खतरा बढ़ता जाता है। कुछ अन्य जोखिम कारकों में आंखों का बढ़ा हुआ दबाव, पारिवारिक इतिहास, मधुमेह या उच्च या निम्न रक्तचाप जैसी स्थितियां, थायरॉयड विकार, आंखों की चोटें, कुछ दवाएं, उच्च अपवर्तक त्रुटियां और स्टेरॉयड का उपयोग शामिल हैं।',
                gly23:'ग्लूकोमा से जुड़े विशिष्ट लक्षणों में दृष्टि में अचानक कमी या क्षणिक हानि, रुक-रुक कर इंद्रधनुषी रंग का आभामंडल दिखना, दोहरी दृष्टि, असाध्य सिरदर्द, पुतली संबंधी असामान्यताएं जैसे पुतली के आकार में अंतर शामिल हैं।',
                gly24:'ग्लूकोमा का इलाज आमतौर पर आपके अंतःनेत्र दबाव को कम करके किया जाता है। आपकी स्थिति की गंभीरता के आधार पर उपचार में प्रिस्क्रिप्शन आई ड्रॉप, लेजर उपचार, मौखिक दवाएं, सर्जरी या इनका संयोजन शामिल हो सकता है।',
                gly5:'बाल चिकित्सा ग्लूकोमा सर्जरी',
                gly55:'बच्चों पर की जाने वाली ग्लूकोमा सर्जरी को बाल चिकित्सा ग्लूकोमा सर्जरी के रूप में जाना जाता है। एक्सटर्नल ट्रैबेकुलोटॉमी और गोनियोटॉमी बाल चिकित्सा ग्लूकोमा सर्जरी के सबसे सामान्य रूप हैं।', 
                gly6:'गैंग्लियन सेल कॉम्प्लेक्स और आरएनएफएल के लिए ओसीटी',
                gly66:'ऑप्टिकल कोहेरेंस टोमोग्राफी (ओसीटी) एक गैर-संपर्क ऑप्टिकल तकनीक है जो गैंग्लियन सेल कॉम्प्लेक्स और रेटिनल तंत्रिका फाइबर परत (आरएनएफएल) की इमेजिंग और माप की अनुमति देती है।',
                gly7:'गोल्डमैन अप्लायनेशन टोनोमेट्री',
                gly77:'यह इंट्राओकुलर दबाव को मापने के लिए उपयोग की जाने वाली सबसे सटीक ऑप्टिकल तकनीकों में से एक है।',
                //
                //corneal
                cor1:'कॉर्निया उपचार',
                corpara1:'हमारे पास वाराणसी में कॉर्निया उपचार के लिए समर्पित बेहतरीन नेत्र डॉक्टरों की एक टीम है। कॉर्निया सेवाओं की हमारी श्रृंखला में कॉर्निया घर्षण उपचार, कॉर्निया अल्सर उपचार, कॉर्निया सर्जरी और बहुत कुछ शामिल हैं।',
                cor12:'कॉर्नियल रोगों के सामान्य कारणों में संक्रमण, आघात, चोटें, जहरीले रसायनों के संपर्क में आना, डिस्ट्रोफी और अपक्षयी विकार, ऑटोइम्यून विकार, एक्टेसिया (पतला होना), पोषण संबंधी कमी, एलर्जी, सौम्य या घातक ट्यूमर, सामान्य आंख की स्थिति जैसे सूखी आंख, ग्लूकोमा आदि शामिल हैं। .',
                cor23:'कॉर्नियल रोग या चोट के लक्षणों में दर्द, डिस्चार्ज, धुंधली दृष्टि, फटना, लालिमा, प्रकाश के प्रति अत्यधिक संवेदनशीलता और कॉर्नियल घाव शामिल हैं।',
                 cor24:'कॉर्निया की स्थिति का इलाज आंखों के पैच, बैंडेज कॉन्टैक्ट लेंस, प्रिस्क्रिप्शन आई ड्रॉप्स या गोलियों से किया जा सकता है। कॉर्निया अध:पतन जैसी उन्नत कॉर्निया बीमारियों का इलाज कॉर्निया प्रत्यारोपण सर्जरी या लेजर उपचार से किया जाता है। केराटोकोनस, एक ऐसी स्थिति जिसके कारण कॉर्निया का आकार विकृत हो जाता है, अक्सर विशेष संपर्क लेंस के साथ प्रबंधित किया जाता है। मुख्य बीमारी का इलाज करके ऑटोइम्यून विकारों से निपटा जाता है। नेत्रालयम में हम अपने अस्पताल में सबसे नवीन और उन्नत कॉर्निया उपचार प्रदान करते हैं।',
                cor5:'पेनेट्रेटिंग केराटोप्लास्टी (पीके):',
                cor55:'यह पूर्ण-मोटाई प्रत्यारोपण प्रक्रिया शल्य चिकित्सा द्वारा कॉर्निया के क्षतिग्रस्त या रोगग्रस्त हिस्से को हटा देती है, इसके बाद दान किए गए मानव कॉर्निया से स्वस्थ ऊतकों का प्रत्यारोपण किया जाता है।',
                cor6:'चिकित्सीय पेनेट्रेटिंग केराटोप्लास्टी (टीपीके): ',
                cor66:'संक्रमण को रोकने, जटिलताओं से बचने और विश्व अखंडता को बनाए रखने के लिए गंभीर फंगल केराटाइटिस के लिए चिकित्सीय पेनेट्रेटिंग केराटोप्लास्टी (टीकेपी) की सिफारिश की जाती है।',
                cor7:'हार्ड कॉन्टैक्ट लेंस (आरजीपी और स्क्लेरल):' ,
                cor77:'कठोर गैस पारगम्य (आरजीपी) संपर्क लेंस केराटोकोनस के रोगियों में दृश्य तीक्ष्णता में सुधार कर सकता है और कॉर्नियल प्रत्यारोपण के बाद जटिलताओं का प्रबंधन कर सकता है। स्क्लेरल लेंस कुशलतापूर्वक कॉर्निया की स्थिति का इलाज करते हैं क्योंकि कॉर्निया से संपर्क करने के बजाय, यह लेंस अपना वजन स्क्लेरा (आंख-सफेद) पर रखता है और कॉर्निया के ऊपर वॉल्ट करता है।',
                //
                ref1:'फोटोरेफ्रैक्टिव केराटेक्टॉमी (PRK)',
                ref11:'फोटोरेफ्रैक्टिव केराटेक्टॉमी (पीआरके) के साथ, नेत्र सर्जन कॉर्निया की ऊपरी परत को हटा देता है, जिसे एपिथेलियम कहा जाता है। फिर लेजर का उपयोग कॉर्निया की अन्य परतों को नया आकार देने और आंख में अनियमित वक्रता को ठीक करने के लिए किया जाएगा।',
                ret1:'क्षतिग्रस्त रेटिना से दृश्य गड़बड़ी हो सकती है, जिसका अगर इलाज न किया जाए तो दृष्टि हानि हो सकती है। काशीनेत्रालय में, हम अत्याधुनिक तकनीक और दर्द रहित प्रक्रियाओं के साथ व्यापक रेटिनल सेवा प्रदान करते हैं। हमारे रेटिनल सर्जन रेटिनल रोगों के निदान और उपचार में अत्यधिक सक्षम हैं, हमारे रोगियों के लिए समय पर और व्यक्तिगत चिकित्सा और शल्य चिकित्सा देखभाल का आश्वासन देते हैं।',
                ret2:'रेटिनल लेजर',
                ret21:'रेटिनल लेजर मैक्यूलर एडिमा और रेटिना में छोटे-छोटे टूटने का इलाज करता है। मैक्यूलर एडिमा (सूजन) रोगग्रस्त रक्त वाहिकाओं से रक्तस्राव के कारण होती है जो रेटिना को रक्त की आपूर्ति करती है, जिससे धुंधली दृष्टि और गंभीर दृष्टि हानि होती है। उन्नत मधुमेह, अत्यधिक निकट दृष्टिदोष, आंखों की चोट और उम्र बढ़ने के कारण रेटिना फटने की समस्या हो सकती है।',
                reth2:'डायबिटिक रेटिनोपैथी के लिए चिकित्सा और शल्य चिकित्सा प्रबंधन',
                ret3:'डायबिटिक रेटिनोपैथी तब होती है जब मधुमेह रेटिना की रक्त वाहिकाओं को नुकसान पहुंचाता है। इसे इसमें वर्गीकृत किया गया है:',
                ret31:'नॉन-प्रोलिफ़ेरेटिव डायबिटिक रेटिनोपैथी:',
                ret32:'माइक्रोएन्यूरिज्म, एक्सयूडेट्स, रेटिनल हेमोरेज और रेटिनल सूजन का कारण बनता है। यह डायबिटिक रेटिनोपैथी का प्रारंभिक चरण है।',
                ret33:'प्रोलिफ़ेरेटिव डायबिटिक रेटिनोपैथी:',
                ret34:'नई, असामान्य रक्त वाहिकाओं के विकास का कारण बनता है जो बड़े कांच के रक्तस्राव और दृष्टि हानि का कारण बन सकता है। यह डायबिटिक रेटिनोपैथी का एक उन्नत चरण है।',
                ret35:'डायबिटिक रेटिनोपैथी का इलाज लेजर, आंखों के इंजेक्शन और सर्जरी से किया जाता है। सर्जरी से आगे संवहनी परिवर्तनों को रोका जा सकता है और दृष्टि बरकरार रखी जा सकती है।',
                reth3:'एआरएमडी उपचार',
                ret41:'उम्र से संबंधित धब्बेदार अध:पतन (एआरएमडी) अक्सर 50 वर्ष से अधिक उम्र के लोगों में होता है और केंद्रीय दृष्टि में धुंधलापन या हानि का कारण बनता है। इसे इसमें वर्गीकृत किया गया है:',
                ret42:'शुष्क धब्बेदार अध:पतन (गैर-नव संवहनी):',
                ret43:'यह उम्र बढ़ने और धब्बेदार ऊतकों के पतले होने के कारण होता है। इससे मैक्युला में और उसके आसपास पीले धब्बे पड़ जाते हैं, जिससे मैक्यूलर ऊतक खराब हो जाते हैं।',
                ret44:'गीला धब्बेदार अध:पतन (नव संवहनी):',
                ret45:'यह रेटिना के नीचे नई रक्त वाहिकाओं के विकास का कारण बनता है, जिससे रक्त और तरल पदार्थ का रिसाव होता है और इलाज न किए जाने पर रेटिना को स्थायी नुकसान होता है।',
                ret46:'एआरएमडी का इलाज लेजर और आंखों के इंजेक्शन से किया जा सकता है।',
                ALLrr:'सर्वाधिकार सुरक्षित।',
                privacypolicy:' गोपनीयता नीति'
}
