<template>
    <section id="landing" class="landing">
        <div class="landall">
            <div class="landcontainer">
                <h1>{{$t('tcoym')}}</h1>
                <p>
                    {{$t("landmsg") }}
                    <br> </p>
                <!-- <button class="readmore">{{$t('read')}}</button> -->
                <a href="/#care" class="readmore">{{$t("read")}}</a>
                <a :href="'tel:' + phoneNumber" class="call">{{$t('call')}}</a>
                <h2>{{ $t('asa') }}</h2>
                    <h2>{{$t('ehcs')}}</h2> <!-- {{$t('discount')}} -->
                <div class="tfservice">
                <div class="landservice">
                    <img src="../assets/Icons/clock.png" alt="">
                    <p>{{$t('hourservice')}}</p>
                </div>
            </div>
            </div>
            <div class="contactall">
            <div class="landcontact">
                <h1>{{$t("BookanAppointmentmsg")}}</h1>
                <p>{{$t("eycdmsg")}}</p>
                <form id="contact-form" action="https://script.google.com/macros/s/AKfycbzY6MKQpvk08wHNvcEB0bULtHde7XobTVhCKqed-fSHg1H3pZAIHEqhidkrf0GyPS3P/exec" class="formcontainer" method="post" @submit.prevent="submitForm">
                    <input type="text" onkeydown="return /[a-z ]/i.test(event.key)" name="Name"  :placeholder="$t('placeholder')" required>
                    <input type="text" onkeydown="return this.value.length <= 9 && /[0-9\b]/.test(event.key) || event.keyCode === 8" name="Contact" :placeholder="$t('placeholder2')"  required>
                    <button type="submit" class="book-button">{{$t('book_now')}}</button>
                </form>
                <div v-if="showAlert" class="alert-message">
                    Appointment Will be Shared soon!
                </div>
            </div>
        </div>
    </div>
    <div class="image">
        <a href="https://wa.me/916307261100"><img src="../assets/Icons/WhatsappIcon.png" alt="" class="whatsapp"></a>
        <a :href="'mailto:' + emailAddress"><img src="../assets/Icons/MailIcon.png" alt="" class="mail"></a>
        <a :href="'tel:' + phoneNumber"><img src="../assets/Icons/PhoneIcon.png" alt="" class="phone"></a> 
    </div>
    </section>
    <Cataract/>
    <About/>
    <Speyecare/>
        <!-- <section id="credentials">
            <div class="credcontainer">
                <div>
                <img src="../assets/Icons/nabh-accredited-dental-clinic-thane.png" class="nabh">
            </div>
                <div>
                <img src="../assets/Icons/QCI.png" class="qci">
            </div>
            </div>
        </section> -->
        <Ourdoctor/>
        <Testimonial/>
        
</template>

<script>
import Cataract from './Cataract.vue';
import About from './About.vue';
import Speyecare from './Speyecare.vue';
import Testimonial from './Testimonial.vue';
import Ourdoctor from './Ourdoctor.vue';
    export default{
        name:'LandingSection',
        components:{ Cataract, About, Speyecare, Testimonial, Ourdoctor },
    
data() {
    return {
        isNavOpen: false,
        showAlert: false,
        phoneNumber: '7309833833',
        emailAddress: 'info@kashinetralaya.com',
        
    };
},
computed: {
    screenWidth() {
        return window.innerWidth;
    },
    
},
methods: {
    toggleNav() {
        this.isNavOpen = !this.isNavOpen;
    },
    submitForm() {
        const form = document.getElementById('contact-form');
            const data = new FormData(form);
            const action = form.action;

            fetch(action, {
                method: 'POST',
                body: data,
            })
                .then(() => {
                this.showAlert = true;
                alert('Appointment will be shared with you soon!');
                form.reset();
                })
                .catch((error) => console.log(`Error submitting contact form ${error}`));
            },
        
        },
    }
</script>

<style>
    .landing{
        background-image: url('../assets/Bg Images/Landing Abstract img.png');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: fit-content;
    }
    .landall{
        font-family: 'Montserrat', sans-serif;
        display: grid;
        grid-template-columns: 4fr 2fr 0.6fr;
    }

    .landcontainer{
        text-align: start;
        padding: 10px 100px;
    }

    .landcontainer .landservice{
        align-items: center;
        display: flex;
        background-color: var(--lightcolor);
        border-radius: 28px;
        width: 220px;
       }

    .landservice p{
        text-align: center;
        padding: 20px 0px;
    }

    .landcontainer h1{
        color: var(--bluecolor);
        font-size: 70px;
        font-weight: 700;
        padding: 60px 0px;
    }
    .landcontainer h2{
        color: var(--redcolor);
        font-size: 20px;
        font-weight: 800;
        margin-top: 10px;
        padding: 10px 0px;
    }
    .landcontainer p{
        font-size: 18px;
        padding-bottom: 20px;
    }

    .formcontainer{
        display: flex;
        flex-direction: column;
        width: 350px;
    }

    .landcontainer .readmore{
        padding: 10px 20px;
        color: var(--whitecolor);
        background-color:var(--bluecolor);
        border: 1px solid var(--bluecolor);
        border-radius: 5px;
        font-weight: 700;
        margin-right: 20px;
    }

    .landcontainer .call{
        padding: 10px 32px;
        color: var(--bluecolor);
        background-color: var(--whitecolor);
        border: 3px solid var(--bluecolor);
        border-radius: 5px;
    }

    .contactall{
        display: flex;
        align-items: center;
    }
    .landcontact {
        align-items: center;
        display: flex;
        flex-direction: column;
        background-color: var(--thirdwhite);
        box-shadow: 20px 20px 30px var(--fourthblack);
        border-radius: 20px;
        width: 400px;
        height: fit-content;
        text-align: center;
        margin: 227px 39px 43px 133px;
    }

    .landcontact h1 {
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        font-weight: 700;
        margin: 0px;
        width: 400px;
        padding: 20px 10px;
        border-radius: 10px 10px 1px 1px;
    }

    .landcontact p{
        color: var(--bluecolor);
        font-weight: 400;
        padding: 10px;
    }

    .formcontainer input{
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        color: var(--bluecolor);
        background-color: var(--lightcolor);
        font-size: 16px;
        border: 1px solid var(--fourthwhite); 
        border-radius: 4px;
    }

    .formcontainer input::placeholder{
        font-style: italic;
        color: var(--bluecolor);
    }

    .formcontainer .book-button{
        background-color: var(--bluecolor);
        color: var(--whitecolor);
        font-weight: 700;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 15px;
        max-width: max-content;
        border: 1px solid var(--blackcolor);
    }

    .alert-message {
        background-color: var(--greencolor); /* Green background color */
        color: var(--seventhwhite); /* White text color */
        padding: 10px; /* Add some padding */
        position: fixed; /* Fixed position at the top */
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        display: none; /* Initially hidden */
    }

    .landall .image{
        padding-top: 324px;

    }

    .image{
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 50%;
        right: 0;
        gap: 10px;
        z-index: 10;
    }

    .image a img{
        width: 70%;
    }

    .credcontainer{
        display: flex;
        justify-content: center;
        background-color: var(--darkblue);
    }

    .credcontainer h1{
        text-align: center;
        color: var(--bluecolor);
        font-size: 70px;
        font-weight: 700;
    }
    @media (max-width: 1340px) {
        .landcontainer{
            padding: 10px;
        }

        .landcontact{
            margin : 20px 20px;
        }
    }

    @media (max-width: 935px){
        .landcontainer{
            text-align: center;
        
        }

        .landcontainer h1{
            font-size: 50px;
        }
        .landcontainer h2{
            font-size: 20px;
        }
        .landcontainer p{
            font-size: 15px;
        }

        .image{
            display: none;
        }
    }

    @media (max-width: 835px) {
        .landall{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .tfservice{
            display: flex;
            justify-content: center;
        }
    }

    @media (max-width: 750px) {
        .landing{
            background: none;
            height: auto;
        }
    }

    @media (max-width: 600px) {
        .landing{
            background: none;
            height: auto;
            padding-bottom:2rem;
        }
        
        .landcontact{
            width: 345px;
        }

        .landcontact h1{
            padding: 10px;
            width: 345px;
        }

        .formcontainer {
            padding: 10px;
        }

        .formcontainer input{
            width: 96%;
        }
    }

</style>
