<template>
<section id="about" class="about_us">
        <h1 class="hero_hed">{{$t('about_us')}}</h1>
        <div class="main">
        <div class="hero_para">
        <p>{{$t('abtmsg1')}}
<br><br>
{{ $t('abtmsg2') }}<br><br>
{{$t('abtmsg3')}}</p>
    </div>
    <div class="review">

     <h2 class="nam">
         {{ currentName }}
        </h2>
     <div class="star"> 
        <img src="../assets/Icons/Stars.png"  class="stars" alt="stars">

     </div>
     <div class="content"> 
      <a href="https://g.co/kgs/1ne8QW" target="_blank">  <p>{{ currentReview }}
        </p></a>
     </div>
    </div>
    </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AboutSection',
  data() {
    return {
      sheetData: [],
      currentIndex: 0,
      currentName: '',
      currentReview: ''
    };
  },
  mounted() {
    this.fetchSheetData();
  },
  methods: {
    async fetchSheetData() {
      try {
        const sheetUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTrfz_tTxaGp97TU_0Pu6XVXX1niii8otQp3VOwe3Rl8Rb4hPOQFrfB-BcVq_-E--ryZL7U-vQ49TLV/pub?output=csv';

        const response = await axios.get(sheetUrl);
        
        const rows = response.data.split('\n');
        
        this.sheetData = rows.map(row => {
          const columns = row.split(',');
          return {
            name: columns[0], 
            review: columns[4] 
          };
        });

        this.displayReviews();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    displayReviews() {
      const totalReviews = this.sheetData.length;
      let index = 0;

      const displayNextReview = () => {
        if (index < totalReviews) {
          this.currentName = this.sheetData[index+1].name || '';
          this.currentReview = this.sheetData[index+1].review || '';

          
          let reviewLength = this.currentReview.length;
          let displayDuration = 5000; 

          if (reviewLength > 200) {
            displayDuration = 15000; 
          } else if (reviewLength >= 70) {
            displayDuration = 10000;
          } else {
            displayDuration = 5000; 
          }
          console.log(displayDuration);
          
          setTimeout(() => {
            index++;
            displayNextReview();
          }, displayDuration);
        }
      };

      displayNextReview();
    }
  }
};
</script>

<style>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none;
  color: inherit;
}
.main{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.about_us{
    width: 100%;
    height: 100%;
    background-image: url("../assets/Bg Images/About Us BG img.png");
    background-size:cover;
    font-family: 'Montserrat', sans-serif;
}
.hero_hed{
    text-align: center;
    padding: 1rem;
    font-size: 4rem;
    font-weight: 700;
    color: var(--bluecolor); 
   
}
.hero_para{
    width: 55%;
    text-align: left;
    padding: 2rem;
    font-size: 1.2rem;

}

.hero_para p{
    font-family: 'Nunito';
}
.review{
    width: 30%;
    height: 100%;
    background-color: var(--seventhwhite);
    box-shadow:5px 5px 5px 5px var(--secondgray);
    padding: 1rem;
    margin: 8rem;
    border-radius: 1rem;
}
.nam{
    text-align: center;
    font-weight: 900;
    font-size: 1.8rem;
    padding-top: 1rem;

}
.stars{
    top:50%;
    left: 50%;
    position:relative;
    transform: translate(-50%,10%);
    padding-bottom: 1rem;
}
.content{
    text-align: center;
}
@media (max-width:1110px){
    .hero_para{
        font-size: 0.9rem;
        font-weight: 600;
    }
   .review{
        width: 40%;
        margin: 4rem;
    }
}
@media (max-width:850px){
    .main{
    flex-direction:column;
    }
    .hero_hed{
        font-size:3rem;
    }

 .review{
        width: 60%;
        margin: 4rem;
    }

   
    .hero_para{
        padding: 1rem;
        width: 100%;
    }
   
    
}
@media (max-width:850px){
     .review{
        width: 80%;
        margin: 2rem;
    }
}
    

</style>