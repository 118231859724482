<template>
    <section id="cataract" class="cataract">
        <div class="cataractsurg">
            <h1>{{$t('cataract')}}</h1>
            <h2>{{$t('surgery')}}</h2>
            <div class="special">
                <h3 class="blade">{{$t('nbns')}}</h3>
                <h3>{{$t('npni')}}</h3>
            </div>
            <p>{{$t('suedo')}}</p>
            <div class="bgimg"></div>
        </div>
    </section>
</template>

<script>
export default{
    name:'CataractSection'
}
</script>

<style>
.cataract{
    background: url('../assets/Icons/Operation.png') center/cover no-repeat,
                linear-gradient(315deg, var(--secondblack) 54.44%, var(--bluecolor) 100%);
                background-size: cover;
        background-position: center;
        width: 100%;
        height: 600px;
}

.cataractsurg{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 679px 1px 20px;
    padding: 40px 70px 1px 100px;
    font-family: 'Montserrat', sans-serif;
}

.cataractsurg h1{
    margin-top: 60px;
    color: var(--whitecolor);
    font-size: 70px;
    font-weight: 700;
    padding-bottom: 20px;
}

.cataractsurg h2{
    color: var(--whitecolor);
    font-size: 50px;
    font-weight: 400;
    padding-bottom: 60px;
}

.cataractsurg .special{
    border: 4px solid var(--whitecolor);
    text-align: center;
    border-radius: 5px;
}

.cataractsurg .special h3{
    font-size: 36px;
    color: var(--whitecolor);
    font-weight: 400;
    padding: 7px 23px;
}
.cataractsurg .special .blade {
    background-color: var(--whitecolor);
    color: var(--blackcolor);
}

.cataractsurg p{
    color: var(--whitecolor);
    padding: 20px 0px;
    font-size: 20px;
}

@media(max-width: 1200px){
    .cataractsurg{
        margin: 10px;

    }
}
@media (max-width: 750px) {
    .cataractsurg{
        align-items: center;
    }

    .cataractsurg p{
        text-align: center;
        font-size: 30px;
    }

    .cataractsurg .special h3{
        font-size: 26px;
    }
    
}

@media (max-width: 600px) {
    .cataract{
        background: none;
        background-color: var(--bluecolor);
        height: fit-content;
    }

    .cataractsurg .bgimg{
        background: url('../assets/Icons/Operation.png') center/cover no-repeat,
                linear-gradient(315deg, var(--secondblack) 54.44%, var(--bluecolor) 100%);
        background-size: cover;
        background-position: center;
        width: 106%;
        height: 230px;
    }

    .cataractsurg{
        padding: 1px;
    }
    
}
</style>